export const CRAFT_ROUTES_BASE = '/craft';
export const CRAFT = 'craft';

export const CRAFT_ROUTES = {
  CRAFT_PAGES: `${CRAFT_ROUTES_BASE}/pages`,
  CRAFT_PAGE_EDITOR: `${CRAFT_ROUTES_BASE}/pages/:property/:id`,
  CRAFT_FEEDS: `${CRAFT_ROUTES_BASE}/feeds`,
  CRAFT_LAYOUTS: `${CRAFT_ROUTES_BASE}/layouts`,
  CRAFT_LAYOUT_EDITOR: `${CRAFT_ROUTES_BASE}/layouts/:property/:id`,
  REDIRECTIONS: `${CRAFT_ROUTES_BASE}/redirections`,
  NEW_REDIRECTION: `${CRAFT_ROUTES_BASE}/redirections/:property`,
  CRAFT_AUTHORS: `${CRAFT_ROUTES_BASE}/authors`,
  CRAFT_NAVIGATIONS: `${CRAFT_ROUTES_BASE}/navigations`,
  CRAFT_NAVIGATION_EDITOR: `${CRAFT_ROUTES_BASE}/navigations/:property/:id`,
  CRAFT_CATEGORIES: `${CRAFT_ROUTES_BASE}/categories`,
  CRAFT_THEMES: `${CRAFT_ROUTES_BASE}/themingTool`,
  CRAFT_THEME_EDITOR: `${CRAFT_ROUTES_BASE}/themingTool/:property/:id`,
  CRAFT_FACTS: `${CRAFT_ROUTES_BASE}/facts`,
  CRAFT_PAGE_BUILDER: `${CRAFT_ROUTES_BASE}/page-builder`,
};
