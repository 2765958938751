import { Component, useEffect, useRef, useState } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { GfycatIcon } from '@ftbpro/mm-admin-assets';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { getStylesObject } from './styles/embed.styles';
import { Plugin } from './Plugin/Plugin';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../utils/plugins.utils';
import { EmbedBlock } from './shared/EmbedBlock';
import { overviewBlockText } from '../utils/blockList.utils';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const GFYCAT_OEMBED_PREFIX = `https://api.gfycat.com/v1/oembed?${OEMBED_QUERY_PARAMS.URL}=`;
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Gfycat. Please paste a correct one.';
const ENTER_GFYCAT_PLACEHOLDER = urlInputPlaceholderGenerator('Gfycat');

// Element-Panel Top Bar Button

export const GfycatEmbedTopBarIcon = props => {
  return (
    <Icon icon={GfycatIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class GfycatEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.GFYCAT;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      gfycatData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.GFYCAT;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { gfycatData } = this.state;
    onAdd(gfycatData);
  };

  onUrlInputKeyDown = e => {
    const { gfycatData } = this.state;
    if (isEnterPressed(e) && gfycatData) {
      this.onAdd(gfycatData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (!this.isValidGfycatUrl(url)) {
      finishLoading();
      this.setState({ gfycatData: null });
      return;
    }
    const oEmbedEndPoint = GFYCAT_OEMBED_PREFIX + encodeURI(url);
    getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.GFYCAT)
      .then(gfycatData => {
        finishLoading();
        this.setState({ gfycatData });
      })
      .catch(() => {
        finishLoading();
        this.setState({ gfycatData: null });
      });
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, gfycatData } = this.state;
    if (!url || isLoading) {
      return null;
    }
    return gfycatData && this.isValidGfycatUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: gfycatData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidGfycatUrl = url => url.includes('gfycat');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { gfycatData, url } = this.state;
    const isAddDisabled = gfycatData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_GFYCAT_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent() }
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const GfycatBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function GfycatEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <GfycatEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const gfycatEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<GfycatEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<GfycatEmbedPanelComponent {...props} />),
  getPluginBlock: props => <GfycatBlock {...props} />,
  getPluginOverviewBlock: props => (<GfycatEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.GFYCAT,
};
