import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const { VMS_PLAYERS_BASE_URL, VMS_VIDEO_BASE_URL, EDITOR_API_BASE_URL, VIDEO_DELIVERY_BASE_URL } = ConfigsProvider;
const EDITOR_SERVICES_BASE_URL = EDITOR_API_BASE_URL;
const VMS_PLAYERS_SERVICES_BASE_URL = `${VMS_PLAYERS_BASE_URL}/v1.0/client`;
const VMS_VIDEO_SERVICES_BASE_URL = VMS_VIDEO_BASE_URL;

const PROPERTIES = '/properties/';
const DRAFTS = '/drafts/';
const SUBMITTED = '/submitted';
const PUBLISHED = '/published';
const MEDIA_SERVICES = '/media';
const IMAGE_SIGNED_URL_ENDPOINT = '/signed-upload-url';
const GETTY_AUTH_TOKEN_REQUEST_URL_ENDPOINT = '/getty-auth-token';
const TWITTER_AUTH_TOKEN_REQUEST_URL_ENDPOINT = '/twitter-auth-token';
const PROXY = '/proxy';
const EDITOR_DRAFTS_ENDPOINT = '/drafts/';
const PREVIEW = '/preview';
const SUBMIT = '/submit';
const UPDATE = '/update';
const IMAGN = '/imagn';
const INSTAGRAM = '/instagram';
const FACEBOOK = '/facebook';
const OEMBED = '/oembed';
const VIDEO_SUFFIX = '_video';
const POST_SUFFIX = '_post';
const BLOCKS = '/blocks/';
const COMMENTS = '/comments';
const WRITING_SUGGESTIONS = '/writing-suggestions';
const WRITERS = '/writers';
const CROWD_SIGNAL = '/crowdsignal';

const PLAYERS = 'players';
const PLAYABLE_VIDEOS = 'playable-videos';
const VIDEO_OEMBED = 'https://oembed.minutemediaservices.com/oembed';

const MANAGEMENT = '/management/';

export class EditorServiceDataProvider {
  static getDraftsEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${DRAFTS}`;
  }

  static getSubmittedDraftsEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${SUBMITTED}`;
  }

  static getPublishedArticlesEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${PUBLISHED}`;
  }

  static getDeleteDraftEndpoint({ property, id }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${DRAFTS}${id}`;
  }

  static getEditorServiceCreateDraftsEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${EDITOR_DRAFTS_ENDPOINT}`;
  }

  static getEditorServiceDraftEndpoint({ property, id }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${EDITOR_DRAFTS_ENDPOINT}${id}`;
  }

  static getEditorServiceSubmitDraftEndpoint({ property, id }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${EDITOR_DRAFTS_ENDPOINT}${id}${SUBMIT}`;
  }

  static getEditorServiceUpdateDraftEndpoint({ property, id, isSilent }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${EDITOR_DRAFTS_ENDPOINT}${id}${UPDATE}${isSilent ? '?isSilentUpdate=true' : ''}`;
  }

  static getEditorServiceCommentsEndpoint({ property, draftId, entityId }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${DRAFTS}${draftId}${BLOCKS}${entityId}${COMMENTS}`;
  }

  static getDeleteCommentsEndpoint({ property, draftId, entityId }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${DRAFTS}${draftId}${BLOCKS}${entityId}${COMMENTS}`;
  }

  static getSignedUploadImageEndpoint(filename, uploadParamsString, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${IMAGE_SIGNED_URL_ENDPOINT}/${filename}?${uploadParamsString}`;
  }

  static getGettyAuthTokenEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${GETTY_AUTH_TOKEN_REQUEST_URL_ENDPOINT}`;
  }

  static getTwitterAuthTokenEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${TWITTER_AUTH_TOKEN_REQUEST_URL_ENDPOINT}`;
  }

  static getInstagramOembedEndpoint(url, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${INSTAGRAM}${OEMBED}?url=${encodeURI(url)}`;
  }

  static getFacebookPostOembedEndpoint(url, queryParam, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${FACEBOOK}${OEMBED}${POST_SUFFIX}?url=${encodeURI(url)}${queryParam}`;
  }

  static getFacebookVideoOembedEndpoint(url, queryParam, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${FACEBOOK}${OEMBED}${VIDEO_SUFFIX}?url=${encodeURI(url)}${queryParam}`;
  }

  static getProxyEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${PROXY}`;
  }

  static getDraftPreviewEndpoint({ property, id, isPremiumArticlePreview }) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${EDITOR_DRAFTS_ENDPOINT}${id}${PREVIEW}${isPremiumArticlePreview ? '?isPremium=true' : ''}`;
  }

  static getVmsPlayersEndpoint({ vmsProperty }) {
    return `${VMS_PLAYERS_SERVICES_BASE_URL}/${PLAYERS}?property=${vmsProperty}&limit=1&order_asc=true`;
  }

  static getSearchVmsVideosEndpoint({ vmsProperty, searchQuery, searchLimit, searchOffset }) {
    const queryString = `qtext=${searchQuery}&limit=${searchLimit}&offset=${searchOffset}`;
    return `${VMS_VIDEO_SERVICES_BASE_URL}${PROPERTIES}${vmsProperty}${MANAGEMENT}${PLAYABLE_VIDEOS}?${queryString}`;
  }

  static getVmsOEmbedEndpoint({ playerId, videoId }) {
    const videoUrl = `${VMS_PLAYERS_BASE_URL}/mplayer_embed.html?playerId=${playerId}&contentId=${videoId}`;
    return `${VIDEO_OEMBED}?url=${encodeURIComponent(videoUrl)}`;
  }

  static getVmsMiniOEmbedEndpoint({ orgId, contentId, subId }) {
    const subIdPart = subId ? `&subId=${subId}` : '';
    return `${VIDEO_DELIVERY_BASE_URL}/oembed/mini?contentId=${contentId}&orgId=${orgId}${subIdPart}`;
  }

  static getImagnSearchEndpoint(path, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${IMAGN}?path=${encodeURIComponent(path)}`;
  }

  static getWritingSuggestions(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${WRITING_SUGGESTIONS}`;
  }

  static getWritersEndpoint(property) {
    const queryString = 'monthsSinceLogin=6';
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${WRITERS}?${queryString}`;
  }

  static getDraftsByAuthorsEndpoint(property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${WRITERS}${DRAFTS}`;
  }

  static getCrowdSignalPollOembedEndpoint(url, queryParam, property) {
    return `${EDITOR_SERVICES_BASE_URL}${PROPERTIES}${property}${MEDIA_SERVICES}${CROWD_SIGNAL}${OEMBED}?url=${encodeURI(url)}${queryParam}`;
  }
}
