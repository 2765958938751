import { injectGlobal } from '@emotion/css';
import { SITE_VARIABLES } from './core/styles/variables';

export const getStylesObject = () => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 0,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh',
  },
  innerContentWrapper: {
    backgroundColor: SITE_VARIABLES.COLORS.BACKGROUND, // TBD - remove after old design is gone - this is for backward compatability
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // Might influence other parts of the project. If it happens I'm sorry.
    minWidth: '0px',
  },
});

export const injectGlobalOverideStyles = () => injectGlobal`
  /*******************************
           Site Overrides
  *******************************/
  body {
    overflow: hidden;
  }

  body  *::-webkit-scrollbar:window-inactive,
  body  *::-webkit-scrollbar{
    width: 8px !important;
  }

  body  *::-webkit-scrollbar-thumb:hover,
  body  *::-webkit-scrollbar-thumb:window-inactive,
  body  *::-webkit-scrollbar-thumb {
    background-color: ${SITE_VARIABLES.COLORS.DARKER_ACTIVE} !important;
    background-clip: content-box;
    border: 2px solid transparent !important;
  }

  body  *::-webkit-scrollbar-track:window-inactive,
  body  *::-webkit-scrollbar-track {
    background: none;
    background-color: transparent !important;
  }
  a {
    text-decoration: none;
  }
`;

export const disableTextVariables = {
  logoHeight: '28px',
  logoWidth: 'auto',
  logoWrapperWidth: 'auto',
};
