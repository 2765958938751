import { useDispatch, useSelector } from 'react-redux';
import { SnackBar } from '@ftbpro/mm-admin-ui-components';
import { capitalizeEveryFirstLetter } from '@ftbpro/mm-admin-core-utils';
import { dismissGlobalNotification } from './store/globalNotifications.actions';
import { globalNotificationsCenterStyles } from './globalNotificationsCenter.styles';
import { globalNotificationsSelector } from './store/globalNotifications.selector';

export const GlobalNotificationsCenter = props => {
  const { logoutAction } = props;
  const dispatch = useDispatch();
  const globalNotifications = useSelector(globalNotificationsSelector.globalNotifications);

  const renderNotifications = () => {
    return globalNotifications.map(({ id, status, text, buttonText = '', onButtonClick, showLogout = false, closeable = true, onCloseAction, stayOpen, animationDuration }) => {
      const onCloseClosure = () => {
        dispatch(dismissGlobalNotification(id));
        return onCloseAction && onCloseAction();
      };

      const onTextButtonAction = showLogout ? logoutAction : onButtonClick;

      return (
        <SnackBar
          key={id}
          status={status}
          text={text}
          textButtonContent={capitalizeEveryFirstLetter(buttonText)}
          onTextButtonClick={onTextButtonAction}
          onClose={closeable ? onCloseClosure : false}
          stayOpen={stayOpen}
          variables={animationDuration ? { animationDuration } : {}}
        />
      );
    });
  };

  return globalNotifications
    ? (
      <div style={globalNotificationsCenterStyles.notificationsWrapper}>
        {renderNotifications()}
      </div>
    )
    : null;
};
