import { useEffect, useRef, useState } from 'react';
import { useUserData } from 'core/hooks/useUserData';
import { getOrgIdFromSubdomain } from 'core/store/user/user.utils';
import { ConfigsProvider } from 'core/configs/ConfigsProvider';

const QUACK_ENABLED_ORGS = ['minutemedia', 'mmsport'];
const QUACK_ENABLED_TENANTS = ['mmsport', 'qa', 'development'];

export function shouldLoadQuack() {
  const orgIdFromSubdomain = getOrgIdFromSubdomain();
  return QUACK_ENABLED_TENANTS.includes(ConfigsProvider.TENANT) && QUACK_ENABLED_ORGS.includes(orgIdFromSubdomain);
}

const SCRIPT = `
    (function (q, u, a, c, k, i, o) {
      q[a] =
        q[a] ||
        function () {
          (q[a].q = q[a].q || []).push(arguments);
        };
      i = u.createElement(c);
      i.async = 1;
      i.id = "quack-license";
      i.src = "https://sdk-quack.s3.amazonaws.com/p/verify.min.js";
      i.setAttribute(a, k);
      o = u.getElementsByTagName(c)[0];
      o.parentNode.insertBefore(i, o);
    })(window, document, "quack", "script", "6DbXHkLADI8Wbi12k32l")
`;

export const QuackScript = () => {
  const userData = useUserData();
  const [time, setTime] = useState(Date.now());
  const { email, userId: id } = userData?.userDetails;
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    const script = document.createElement('script');

    script.innerHTML = SCRIPT;
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'quack-license');
    script.setAttribute('data-quack-license', '6DbXHkLADI8Wbi12k32l');

    body?.appendChild(script);
  }, []);

  useEffect(() => {
    const localInterval = setInterval(() => {
      setTime(Date.now());
    }, 1000);
    interval.current = localInterval;

    return () => clearInterval(localInterval);
  }, []);

  useEffect(() => {
    if ((window as any)?.quack && (window as any)?.quack?.identify) {
      console.debug('quack loaded');
      if (!identified) {
        (window as any)?.quack?.identify({
          email,
          id,
        });
        setIdentified(true);
      }
      interval.current && clearInterval(interval.current);
    }
  }, [email, id, time, identified]);

  return null;
};
