import { Fragment, useEffect, useState } from 'react';
import { Filter, Text, Types } from '@ftbpro/mm-admin-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { Product } from 'core/constants';
import { fetchPropertiesByOrganization, resetPropertiesByOrganization } from '../../store/property/propertySlice';
import { FILTER_VARIABLES, getStylesObject } from './navigationFilters.styles';
import {
  findOrganizationByName,
  getOrganizationsName,
  getOrganizationsByProduct,
  getSelectedOrganization,
} from './navigationFilters.utils';
import {
  fetchOrganizationsByTenant,
  OrganizationListItem,
  OrganizationSliceState,
  setCurrentOrganization,
} from '../../store/organization/organizationSlice';
import { organizationSelector } from '../../store/organization/organization.selector';
import { useStyles } from '../../hooks/useStyles';

const PLACEHOLDER = 'Organization';

export const OrganizationFilter = (): JSX.Element => {
  const styles = useStyles(getStylesObject, []);
  const dispatch = useDispatch();
  const currentTenant = useSelector(organizationSelector.currentTenant);
  const product = useSelector(navigationFiltersSelector.product) as Product;
  const preSelectedOrganization = useSelector(navigationFiltersSelector.preSelectedOrganization);
  const disabled = useSelector(navigationFiltersSelector.disableOrganizationSelection);
  const shouldFilterByProduct = useSelector(navigationFiltersSelector.shouldFilterByProduct);
  const currentOrganization = useSelector((state: OrganizationSliceState) => organizationSelector.currentOrganization(state, product));
  const availableOrganizations = useSelector(organizationSelector.availableOrganizations);
  const [organizationsList, setOrganizationsList] = useState([] as OrganizationListItem[]);

  useEffect(() => {
    if (currentTenant) {
      dispatch(fetchOrganizationsByTenant());
    }
  }, [currentTenant, dispatch]);

  useEffect(() => {
    if (!isEmpty(availableOrganizations)) {
      setOrganizationsList(shouldFilterByProduct ? getOrganizationsByProduct(availableOrganizations, product) : availableOrganizations);
    }
  }, [availableOrganizations, product]);

  useEffect(() => {
    if (!isEmpty(availableOrganizations)) {
      const selectedOrganization = getSelectedOrganization(preSelectedOrganization, currentOrganization, availableOrganizations);
      dispatch(setCurrentOrganization({
        selectedOrganization,
        product,
      }));
    }
  }, [availableOrganizations, product, preSelectedOrganization]);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(fetchPropertiesByOrganization({
        organizationId: currentOrganization.id,
        productId: shouldFilterByProduct ? product : '',
      }));
    }
  }, [currentOrganization, product, dispatch]);

  const onOrganizationChange = (e: Types.ClickEventType, { value: organizationName }: { value: string | number }) => {
    if (currentOrganization?.name !== organizationName) {
      const selectedOrganization = findOrganizationByName(availableOrganizations, organizationName as string);
      dispatch(setCurrentOrganization({
        selectedOrganization,
        product,
      }));
      dispatch(resetPropertiesByOrganization({ product }));
    }
    return null;
  };

  return (
    <Fragment>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M} style={styles.scopeFilterSeparator}>/</Text>
      <Filter
        placeholder={PLACEHOLDER}
        items={getOrganizationsName(organizationsList)}
        disabled={disabled}
        variables={FILTER_VARIABLES}
        onSelectedChange={onOrganizationChange}
        selectedValue={currentOrganization?.name}
      />
    </Fragment>
  );
};
