import { Checkbox, CssTooltip, Input, Text,
  // TOOLTIP_POSITIONS
} from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { linkableImageSettingsStyles } from '../linkableImageSettings.styles';
import { LINKABLE_IMAGE_SETTINGS_TEXT } from './linkableImageSettingsComponent.constants';

export const LinkableImageSettingsBody = ({
  checked,
  linkableImageLinkError,
  shouldNoFollow,
  targetLink,
  shouldOpenNewTab,
  changeLinkableImageSettings,
}) => {
  return checked
    ? (
      <>
        <Input
          style={linkableImageSettingsStyles.inputItem}
          error={linkableImageLinkError}
          onChange={(e, data) => {
            return changeLinkableImageSettings({
              link: data,
              open: shouldOpenNewTab,
              follow: shouldNoFollow,
            });
          }}
          value={targetLink}
          placeholder={LINKABLE_IMAGE_SETTINGS_TEXT.INPUT_PLACEHOLDER}
          variables={{ width: '100%' }}
          multiline
          rows={1}
          autoHeight
        />
        <Checkbox
          checked={shouldOpenNewTab}
          onChange={(e, data) => {
            return changeLinkableImageSettings({
              link: targetLink,
              open: data.checked,
              follow: shouldNoFollow,
            });
          }}
          style={linkableImageSettingsStyles.checkboxItem}
        >
          <div css={css(linkableImageSettingsStyles.titleContainer)}>
            <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>
              {LINKABLE_IMAGE_SETTINGS_TEXT.CHECKBOX_NEW_TAB_LABEL}
            </Text>
            <CssTooltip
              content={LINKABLE_IMAGE_SETTINGS_TEXT.CHECKBOX_NEW_TAB_TOOLTIP}
              style={linkableImageSettingsStyles.toggle}
              multiline
            />
          </div>
        </Checkbox>
        <Checkbox
          checked={shouldNoFollow}
          style={linkableImageSettingsStyles.checkboxItem}
          onChange={(e, data) => {
            return changeLinkableImageSettings({
              link: targetLink,
              open: shouldOpenNewTab,
              follow: data.checked,
            });
          }}
        >
          <div css={css(linkableImageSettingsStyles.titleContainer)}>
            <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>
              {LINKABLE_IMAGE_SETTINGS_TEXT.CHECKBOX_FOLLOW_LABEL}
            </Text>
            <CssTooltip
              content={LINKABLE_IMAGE_SETTINGS_TEXT.CHECKBOX_FOLLOW_TOOLTIP}
              style={linkableImageSettingsStyles.toggle}
              multiline
            />
          </div>
        </Checkbox>
      </>
    ) : null;
};
