import { css } from '@emotion/react';
import { CssTooltip, Icon, Text, Toggle } from '@ftbpro/mm-admin-ui-components';
import { InfoIcon } from '@ftbpro/mm-admin-assets';
import { linkableImageSettingsStyles } from '../linkableImageSettings.styles';
import { LINKABLE_IMAGE_SETTINGS_TEXT } from './linkableImageSettingsComponent.constants';
import { SITE_VARIABLES } from '../../../../../core/styles/variables';

export const LinkableImageSettingsTitle = ({ onToggleChange, checked }) => {
  return (
    <div css={css(linkableImageSettingsStyles.titleContainer)}>
      <div css={css(linkableImageSettingsStyles.titleLabel)}>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>
          {LINKABLE_IMAGE_SETTINGS_TEXT.TITLE}
        </Text>
        <CssTooltip
          content={LINKABLE_IMAGE_SETTINGS_TEXT.INPUT_TOOLTIP}
          style={linkableImageSettingsStyles.titleTooltip}
          multiline
          position="right"
          trigger={(
            <Icon
              icon={InfoIcon}
              height={12}
              width={12}
              color={SITE_VARIABLES.COLORS.TERTIARY}
            />
          )}
        />
      </div>

      <Toggle onChange={onToggleChange} checked={checked} />
    </div>
  );
};
