import { Component, useEffect } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { ApesterIcon } from '@ftbpro/mm-admin-assets';
import { css } from '@emotion/react';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getOembedData } from '../services/oembed/oEmbedApiProvider';
import { getStylesObject } from './styles/embed.styles';
import { Plugin } from './Plugin/Plugin';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../utils/plugins.utils';
import { EmbedBlock } from './shared/EmbedBlock';
import { overviewBlockText } from '../utils/blockList.utils';
import { loadApester } from '../../services/apesterServices';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const APESTER_OEMBED_PREFIX = `https://display.apester.com/v1/oembed?${OEMBED_QUERY_PARAMS.URL}=`;
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Apester. Please paste a correct one.';
const ENTER_APESTER_PLACEHOLDER = urlInputPlaceholderGenerator('Apester');

// Element-Panel Top Bar Button

export const ApesterEmbedTopBarIcon = props => {
  return (
    <Icon icon={ApesterIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class ApesterEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.APESTER;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      apesterData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.APESTER;
  }

  componentDidUpdate(prevProps, prevState) {
    const { apesterData } = this.state;
    const needsLoad = apesterData && apesterData !== prevState.apesterData;
    if (needsLoad) {
      loadApester();
    }
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { apesterData } = this.state;
    onAdd(apesterData);
  };

  onUrlInputKeyDown = e => {
    const { apesterData } = this.state;
    if (isEnterPressed(e) && apesterData) {
      this.onAdd(apesterData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    if (!this.isValidApesterUrl(url)) {
      finishLoading();
      this.setState({ apesterData: null });
      return;
    }
    const oEmbedEndPoint = APESTER_OEMBED_PREFIX + encodeURI(url);
    getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.APESTER)
      .then(apesterData => {
        finishLoading();
        this.setState({ apesterData });
      })
      .catch(() => {
        finishLoading();
        this.setState({ apesterData: null });
      });
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, apesterData } = this.state;
    if (!url || isLoading) {
      return null;
    }
    return apesterData && this.isValidApesterUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: apesterData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidApesterUrl = url => url.includes('apester');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { apesterData, url } = this.state;
    const isAddDisabled = apesterData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_APESTER_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent() }
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const ApesterBlock = props => {
  useEffect(() => {
   loadApester();
      });

  const embedBlockProps = { ...props };

  return <EmbedBlock {...embedBlockProps} />;
};

export function ApesterEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <ApesterEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const apesterEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<ApesterEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<ApesterEmbedPanelComponent {...props} />),
  getPluginBlock: props => <ApesterBlock {...props} />,
  getPluginOverviewBlock: props => (<ApesterEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.APESTER,
};
