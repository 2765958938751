import { Component, useEffect, useRef, useState } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';

import { EmbedIcon } from '@ftbpro/mm-admin-assets';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { Plugin } from './Plugin/Plugin';

import { pluginAddButtonTextHandler } from '../utils/plugins.utils';

import { HtmlEmbedBlock } from './shared/HtmlEmbedBlock';
import {
  formatEmbedForEditor,
  getIframeEmbedData,
  isValidIframeEmbedCode,
} from './IFrameEmbed.utils';
import { getStylesObject } from './styles/embed.styles';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';

export const EMBED_BROKEN_HTML_TEXT = 'The embed code you are using is not valid. Please make sure that you are using an iframe embed code.';
const ENTER_IFRAME_EMBED_CODE_PLACEHOLDER = 'Paste an iframe embed code';

export const IframeEmbedTopBarIcon = props => {
  return <Icon icon={EmbedIcon} width={28} height={28} {...props} />;
};

export class IframeEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.IFRAME_EMBED;
    this.state = {
      embedCodeHTMLString: isEditingBlock
        ? editedBlockData.value.embedCodeHTMLString
        : '',
      iFrameEmbedData: null,
    };
  }

  componentDidMount() {
    const { embedCodeHTMLString } = this.state;
    if (embedCodeHTMLString) {
      this.getIframeEmbedVideoData(embedCodeHTMLString);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.IFRAME_EMBED;
  }

  onEmbedFieldKeyDown = e => {
    const { iFrameEmbedData } = this.state;
    if (isEnterPressed(e) && iFrameEmbedData) {
      this.onAdd(iFrameEmbedData);
    }
  };

  onInputChange = e => {
    const embedCodeHTMLString = e.target.value;
    this.setState(
      { embedCodeHTMLString },
      this.getIframeEmbedVideoData(embedCodeHTMLString),
    );
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { iFrameEmbedData } = this.state;
    onAdd(iFrameEmbedData);
  };

  getIframeEmbedVideoData = embedCodeHTMLString => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    const data = formatEmbedForEditor(embedCodeHTMLString);
    if (isValidIframeEmbedCode(data)) {
      this.setState({
        iFrameEmbedData: {
          ...getIframeEmbedData(data),
        },
      });
    }
    finishLoading();
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { embedCodeHTMLString, iFrameEmbedData } = this.state;
    const data = formatEmbedForEditor(embedCodeHTMLString);
    if (data.trim() === '' || isLoading) {
      return null;
    }
    return iFrameEmbedData && isValidIframeEmbedCode(data) ? (
      <div
        dangerouslySetInnerHTML={{ __html: data }} //eslint-disable-line
        css={css(getStylesObject().embed)}
      />
    ) : (
      <Plugin.ErrorMsgComponent text={EMBED_BROKEN_HTML_TEXT} />
    );
  };

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { embedCodeHTMLString, iFrameEmbedData } = this.state;
    const data = formatEmbedForEditor(embedCodeHTMLString);
    const isAddDisabled = iFrameEmbedData === null || data.trim() === '';

    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={data}
            placeholder={ENTER_IFRAME_EMBED_CODE_PLACEHOLDER}
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onEmbedFieldKeyDown}
            multiline
            autoHeight
          />
          {this.getPreviewComponent()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

export const IframeEmbedBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);
  const { data } = props;

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  const addRiddleStyles = () => {
    blockRef.current.querySelector('iframe').style.cssText = 'position: absolute; top: 0; left: 0; height: 100%';
    blockRef.current.getElementsByClassName(
      'riddle2-wrapper',
    )[0].style.paddingTop = '110%';
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });

      if (data.value.embedCodeHTMLString.includes('riddle2-wrapper')) {
        addRiddleStyles();
      }
    }
  });

  const embedBlockProps = {
    ...props,
    className: 'iframe-embed-video',
    loading,
    blockRef,
  };

  return <HtmlEmbedBlock {...embedBlockProps} />;
};

const IframeEmbedPlayerEmbedOverview = () => {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <IframeEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
    </Plugin.OverviewBlock>
  );
};

export const iframeEmbedPluginData = {
  getPluginTopBarButtonIcon: props => <IframeEmbedTopBarIcon {...props} />,
  getPluginPanelComponent: props => <IframeEmbedPanelComponent {...props} />,
  getPluginBlock: props => <IframeEmbedBlock {...props} />,
  getPluginOverviewBlock: props => (
    <IframeEmbedPlayerEmbedOverview {...props} />
  ),
  pluginBlockType: BLOCK_TYPES.IFRAME_EMBED,
};
