import _toConsumableArray from "/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var c = function (o) {
    return o.Loaded = "loaded", o.ContextChanged = "contextChanged", o.UserData = "userData", o.Error = "error", o.MouseAndKeyboardEvent = "mouseAndKeyboardEvent", o.RouteChanged = "RouteChanged", o;
  }(c || {}),
  n = {
    loaded: [],
    contextChanged: [],
    userData: [],
    mouseAndKeyboardEvent: [],
    error: [],
    RouteChanged: []
  },
  y = function y(e) {
    return e.replace(/\/$/, "");
  },
  M = function M(e) {
    return function (t) {
      if (t.origin === y(e)) {
        var s;
        try {
          s = JSON.parse(t.data);
        } catch (a) {
          if (a instanceof Error) {
            var i = a.message,
              o = a.name,
              p = a.stack;
            s = {
              type: "error",
              data: {
                message: i,
                name: o,
                stack: p
              }
            };
          }
        }
        var d = n[s.type];
        d !== void 0 && d.forEach(function (a) {
          return a(s);
        });
      }
    };
  };
function u(e) {
  window.addEventListener("message", M(e), !1);
}
function T(e) {
  n.loaded = [].concat(_toConsumableArray(n.loaded), [e]);
}
function r(e, t, s) {
  t.contentWindow && t.contentWindow.postMessage(JSON.stringify(e), s);
}
function g(e, t) {
  window.parent.postMessage(JSON.stringify(e), t);
}
function v(e, t, s) {
  r({
    data: e,
    type: "userData"
  }, t, s);
}
function f(e, t, s) {
  r({
    data: e,
    type: "contextChanged"
  }, t, s);
}
function m(e, t) {
  window.addEventListener("keydown", function (s) {
    var a = {
      data: {
        type: s.type,
        key: s.key,
        code: s.code
      },
      type: "mouseAndKeyboardEvent"
    };
    r(a, e, t);
  }), window.addEventListener("click", function (s) {
    var a = {
      data: {
        type: s.type
      },
      type: "mouseAndKeyboardEvent"
    };
    r(a, e, t);
  });
}
function E(e) {
  g({
    data: null,
    type: "loaded"
  }, e);
}
function C(e, t) {
  g({
    data: t,
    type: "RouteChanged"
  }, e);
}
function x(e) {
  n.userData = [].concat(_toConsumableArray(n.userData), [e]);
}
function h(e) {
  n.contextChanged = [].concat(_toConsumableArray(n.contextChanged), [e]);
}
function l(e) {
  n.mouseAndKeyboardEvent = [].concat(_toConsumableArray(n.mouseAndKeyboardEvent), [e]);
}
function D(e) {
  n.RouteChanged = [].concat(_toConsumableArray(n.RouteChanged), [e]);
}
export { c as MessageType, u as listenToMessages, h as onContextChanged, T as onIframeLoaded, D as onRouteChange, x as onUserDataRecieved, l as onUserEventRecieved, f as sendContextChanged, m as sendEventsToIframe, E as sendIframeLoaded, C as sendRouteChanged, v as sendUserData };