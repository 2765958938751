import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { ELEMENT_PANEL_PLUGIN_TYPES } from '../plugins/pluginConfiguration';
import {
  ELEMENT_PANEL_CONTEXT,
  UPLOAD_IMAGE_TOOLTIP,
  USER_UPLOAD_PROVIDER,
} from '../components/elementPanel/elementPanel.constants';
import { ImageServices } from '../../services/imageServices/imageServiceApiProvider';
import {
  GETTY_IMAGES_PROVIDER,
  IMAGE_EXCHANGE_PROVIDER,
  IMAGN_IMAGES_PROVIDER,
  MEDIA_LIBRARY_EMBED_PROVIDER,
} from '../plugins/shared/plugins.constants';
import { AVAILABLE_UPLOAD_SOURCES } from '../../services/imageServices/imageService.constants';
import { VoltaxRole } from '../../../../core/store/user/roles.constants';
import { LIVE_BLOG_PROPERTY } from '../plugins/LiveBlog/liveBlog.constants';

function getAllowedPluginTypesForCover() {
  return [
    ELEMENT_PANEL_PLUGIN_TYPES.EMPTY_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.YOUTUBE_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.MM_PLAYER_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.MEDIA_LIBRARY_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.IMAGE_EXCHANGE_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL,
  ];
}

export function isEditingImage(currentActivePanelType) {
  const imageEditingPanels = [
    ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.MEDIA_LIBRARY_EMBED_PANEL,
    ELEMENT_PANEL_PLUGIN_TYPES.IMAGE_EXCHANGE_PANEL,
  ];
  return imageEditingPanels.includes(currentActivePanelType);
}

export function isEditingCover(currentActivePanelType, elementPanelContext) {
  return getAllowedPluginTypesForCover().includes(currentActivePanelType) && elementPanelContext === ELEMENT_PANEL_CONTEXT.EDITING_COVER_IMAGE;
}

export function shouldDisablePlugin(requiredRoles, userRoles) {
  if (isEmpty(requiredRoles)) {
    return false;
  }
  const intersectedRoles = requiredRoles.filter(role => {
    return userRoles.includes(role);
  });
  return intersectedRoles.length === 0;
}

export function shouldDisableTopBarButton(panelType, elementPanelContext) {
  return elementPanelContext === ELEMENT_PANEL_CONTEXT.EDITING_COVER_IMAGE && !getAllowedPluginTypesForCover().includes(panelType);
}

export function shouldDisableImageUpload(panelType, userRoles) {
  const isAbleToUpload = userRoles.includes(VoltaxRole.MediaAdmin) || userRoles.includes(VoltaxRole.UploadImages);
  return panelType === ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL && !isAbleToUpload;
}

export function shouldShowUploadTooltip(panelType, userRoles) {
  return shouldDisableImageUpload(panelType, userRoles) ? UPLOAD_IMAGE_TOOLTIP : false;
}

function convertCaptionToValidFileName(caption = '') {
  return caption.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}

function getImageDescriptionFromGettyImage(image) {
  const { people, title } = image;
  return people && people.length ? people.join(', ') : title;
}

export function uploadThumbnailForEmbedObject(oEmbedObject, property) {
  const embedObjectTitle = oEmbedObject.caption || oEmbedObject.title;
  const filename = convertCaptionToValidFileName(embedObjectTitle);
  return ImageServices.uploadImage({
    source: AVAILABLE_UPLOAD_SOURCES.EDITOR,
    filename,
    imageData: oEmbedObject.thumbnailUrl,
    property,
  }).then(response => {
    const { url, path, fileExtension } = response;
    const { caption, description, provider, credit, alt } = oEmbedObject;
    return {
      url,
      path,
      fileExtension,
      fullImageUrl: url,
      caption,
      description,
      provider,
      credit,
      alt,
    };
  });
}

function getGettyImageData({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) {
  const { url, path, fileExtension, fullImageUrl } = uploadResponse;
  const description = getImageDescriptionFromGettyImage(image);
  return {
    url,
    path,
    fileExtension,
    fullImageUrl: fullImageUrl || url,
    caption,
    description,
    provider: GETTY_IMAGES_PROVIDER,
    credit,
    alt,
    aspectRatio,
    sizeType,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
  };
}

function getImageExchangeImageData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) {
  const { description, url, path, fileExtension, fullImageUrl } = uploadResponse;
  return {
    url,
    path,
    fileExtension,
    fullImageUrl: fullImageUrl || url,
    caption,
    description,
    provider: IMAGE_EXCHANGE_PROVIDER,
    credit,
    alt,
    aspectRatio,
    sizeType,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
  };
}

export function getImageFromDriveData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, provider, cropping }) {
  const { url, path, fileExtension, fullImageUrl } = uploadResponse;
  return {
    url,
    path,
    fileExtension,
    fullImageUrl: fullImageUrl || url,
    caption,
    description: caption,
    provider: provider || USER_UPLOAD_PROVIDER,
    credit,
    alt,
    aspectRatio,
    sizeType,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
  };
}

function getImagnImageData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) {
  const { url, path, fileExtension, fullImageUrl } = uploadResponse;
  return {
    url,
    path,
    fileExtension,
    fullImageUrl: fullImageUrl || url,
    caption,
    description: caption,
    provider: IMAGN_IMAGES_PROVIDER,
    credit,
    alt,
    aspectRatio,
    sizeType,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
  };
}

function getMediaLibraryImageData({ caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, fullImageUrl, cropping, url, path }) {
  return {
    url,
    path,
    fileExtension: 'jpg',
    fullImageUrl: fullImageUrl || url,
    caption,
    description: caption,
    provider: MEDIA_LIBRARY_EMBED_PROVIDER,
    credit,
    alt,
    aspectRatio,
    sizeType,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
  };
}

const ACTIVE_PANEL_TO_IMAGE_DATA = {
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL]: ({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) => {
    return getGettyImageData({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping });
  },
  [ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL]: ({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) => {
    return getGettyImageData({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping });
  },
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGE_EXCHANGE_PANEL]: ({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) => {
    return getImageExchangeImageData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping });
  },
  [ELEMENT_PANEL_PLUGIN_TYPES.CROP_IMAGE_PANEL]: ({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, provider, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) => {
    return getImageFromDriveData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, provider, linkURL, linkTargetAttribute, linkRelAttribute, cropping });
  },
  [ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL]: ({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping }) => {
    return getImagnImageData({ uploadResponse, caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping });
  },
  [ELEMENT_PANEL_PLUGIN_TYPES.MEDIA_LIBRARY_EMBED_PANEL]: ({ caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping, url, fullImageUrl, path }) => {
    return getMediaLibraryImageData({ caption, credit, alt, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, cropping, url, fullImageUrl, path });
  },
};

export function getImageData({ uploadResponse, caption, credit, alt, image, aspectRatio, sizeType, linkURL, linkTargetAttribute, linkRelAttribute, currentActivePanelType, provider, cropping, url, fullImageUrl, path }) {
  return ACTIVE_PANEL_TO_IMAGE_DATA[currentActivePanelType]({
    uploadResponse,
    caption,
    credit,
    alt,
    image,
    aspectRatio,
    sizeType,
    provider,
    linkURL,
    linkTargetAttribute,
    linkRelAttribute,
    cropping,
    url,
    fullImageUrl,
    path,
  });
}

export const shouldHideLiveBlog = (currentProperty, pluginName) => {
  return currentProperty?.slug
  && currentProperty?.slug !== LIVE_BLOG_PROPERTY
  && pluginName === ELEMENT_PANEL_PLUGIN_TYPES.LIVE_BLOG_EMBED_PANEL;
};

export const shouldDisableImagn = (organizationId, pluginName, currentProperty) => {
  return organizationId === 'si' && currentProperty?.slug === 'si_mexico' && pluginName === ELEMENT_PANEL_PLUGIN_TYPES.IMAGN_EMBED_PANEL;
};

export const shouldDisableGettyImages = (organizationId, pluginName, currentProperty) => {
  return (organizationId === 'si' || currentProperty?.slug === 'thebiglead_en_international_web')
  && pluginName === ELEMENT_PANEL_PLUGIN_TYPES.GETTY_EMBED_PANEL;
};

export const shouldDisableGettyCreativeImages = (currentProperty, pluginName) => {
  return currentProperty?.slug !== 'mentalfloss'
  && pluginName === ELEMENT_PANEL_PLUGIN_TYPES.GETTY_CREATIVE_EMBED_PANEL;
};

export const shouldDisableCrowdSignal = (currentProperty, pluginName) => {
  return currentProperty?.slug !== 'high_school'
    && pluginName === ELEMENT_PANEL_PLUGIN_TYPES.CROWD_SIGNAL_EMBED_PANEL;
};
