import { Component } from 'react';
import { Plugin } from './Plugin/Plugin';

export class EmptyPanelComponent extends Component {
  render() {
    const { onCancel } = this.props;
    return (
      <Plugin.Container>
        <Plugin.Content />
        <Plugin.Buttons
          isAddDisabled
          onCancelClick={onCancel}
        />
      </Plugin.Container>
    );
  }
}

export const emptyPanelPluginData = {
  getPluginTopBarButtonIcon: null,
  getPluginPanelComponent: props => (<EmptyPanelComponent {...props} />),
  pluginBlockType: null,
};
