import { lazy } from 'react';
import { Redirect, Switch } from 'react-router';
import { ROUTE_VMS_PREFIX } from 'containers/vms/vms.constants';
import { CONTRIBUTORS_ANALYTICS_ROUTES } from 'containers/contributorsAnalytics/analytics.constants';
import { GlobalRole } from '../../core/store/user/roles.constants.hq';
import { ProtectedRoute } from '../../core/components/routes/ProtectedRoute';
import { VoltaxRole } from '../../core/store/user/roles.constants';
import { CMS_ROUTES_BASE } from '../../containers/cms/editor/utils/cmsRouter.constants';
import { CRAFT_ROUTES_BASE } from '../../containers/craft/craftRouting.constants';
import { ADMINISTRATION_ROUTES } from '../../containers/administration/administration.constants';
import { ANALYTICS_ROUTES } from '../../containers/reports/reports.constants';
import { AD_SERVER_ROUTES } from '../../containers/adServer/adServerNavigator';

const LazyCMS = lazy(() => import(/* webpackChunkName: "cms" */ '../../containers/cms'));
const LazyCraft = lazy(() => import(/* webpackChunkName: "craft" */ '../../containers/craft'));
const LazyVMS = lazy(() => import(/* webpackChunkName: "vms" */ '../../containers/vms'));
const LazyReports = lazy(() => import(/* webpackChunkName: "reports" */ '../../containers/reports'));
const LazyAdministration = lazy(() => import(/* webpackChunkName: "administration" */ '../../containers/administration'));
const LazyMediaLibrary = lazy(() => import(/* webpackChunkName: "mediaLibrary" */ '../../containers/mediaLibrary'));
const LazyAdServer = lazy(() => import(/* webpackChunkName: "adServer" */ '../../containers/adServer'));
const LazyContributorsAnalytics = lazy(() => import(/* webpackChunkName: "contributorsAnalytics" */ '../../containers/contributorsAnalytics'));

// TODO: try to change into a config object instead of a component ?

export const Routes = ({ defaultPage }) => (
  <Switch>
    <ProtectedRoute path={CMS_ROUTES_BASE} component={LazyCMS} />
    <ProtectedRoute path={CRAFT_ROUTES_BASE} component={LazyCraft} />
    <ProtectedRoute path={ROUTE_VMS_PREFIX} component={LazyVMS} />
    <ProtectedRoute
      allowNoProperties
      allowNoOrganization
      path={[ANALYTICS_ROUTES.BASE]}
      component={LazyReports}
    />
    <ProtectedRoute
      allowNoProperties
      allowedRoles={[VoltaxRole.OrganizationAdmin, GlobalRole.MegaAdmin]}
      path={ADMINISTRATION_ROUTES.BASE}
      component={LazyAdministration}
    />
    <ProtectedRoute
      allowNoProperties
      path={AD_SERVER_ROUTES.AD_SERVER_BASE}
      component={LazyAdServer}
    />
    <ProtectedRoute path="/mediaLibrary" component={LazyMediaLibrary} />
    <ProtectedRoute path={CONTRIBUTORS_ANALYTICS_ROUTES.BASE} component={LazyContributorsAnalytics} />
    <ProtectedRoute
      allowNoOrganization
      allowNoProperties
      path="/"
      component={() => <Redirect to={defaultPage} />}
    />
  </Switch>
);
