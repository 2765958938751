import { Component, Fragment, useEffect, useState } from 'react';
import { Icon, Input, RadioGroup, SearchInput, Text, TextButton } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { MenuVmsIcon } from '@ftbpro/mm-admin-assets';
import { EditorNetworkService } from '../../services/editorServiceApiProvider';
import { getMiniPlayerOembedDataByProxy, getMMPlayerOembedDataByProxy } from '../../services/oembed/oEmbedApiProvider';
import { EditorServiceDataProvider } from '../../services/editorServiceConfigsProvider';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import { Plugin } from '../Plugin/Plugin';
import { MMPlayerBlock } from './MMPlayerBlock';
import { VideosList } from './VideosList';

import { pluginAddButtonTextHandler } from '../../utils/plugins.utils';
import {
  extractMiniPlayerFieldsFromEmbed,
  extractMMPlayerFieldsFromEmbed,
  getOrgIdByTenant,
  getSearchProperty,
} from './MMPlayerEmbed.utils';
import { transformCmsPropertyToVmsProperty } from '../../../../../core/utils/utils';

import {
  MMPLAYER_RADIO_OPTIONS,
  MMPLAYER_SEARCH_MODES,
  EMBED_BROKEN_URL_TEXT,
  ENTER_MMPLAYER_EMBED_CODE_PLACEHOLDER,
  MMPLAYER_SEARCH_PLACEHOLDER,
  MMPLAYER_ERRORS,
  MMPLAYER_LOAD_MORE_TEXT,
  VMS_SEARCH_LIMIT,
  VMS_SEARCH_OFFSET,
} from './mmPlayer.constants';

import { getStylesObject as getEmbedStylesObject } from '../styles/embed.styles';
import { getStylesObject } from './mmPlayer.styles';
import { pluginInputStyle } from '../shared/styles/pluginInput.styles';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';

export const MMPlayerEmbedTopBarIcon = props => {
  return (
    <Icon icon={MenuVmsIcon} width={28} height={28} {...props} />
  );
};

export class MMEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.MM_PLAYER;
    const previewHtml = editedBlockData && (editedBlockData.value.embedCodeHTMLString || editedBlockData.value.previewHtml);
    this.currentSearchRequest = '';
    this.searchLimit = VMS_SEARCH_LIMIT;
    this.searchOffset = 0;
    this.state = {
      mmPlayerData: null,
      previewHtml: isEditingBlock ? previewHtml : '',
      searchMode: isEditingBlock ? MMPLAYER_SEARCH_MODES.EMBED : MMPLAYER_SEARCH_MODES.SEARCH,
      searchQuery: '',
      videos: [],
      selectedVideoData: null,
    };
  }

  componentDidMount() {
    const { previewHtml } = this.state;
    if (previewHtml) {
      this.getMMPlayerVideoData(previewHtml);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.MM_PLAYER;
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.state;
    if (searchQuery !== prevState.searchQuery) {
      this.searchOffset = 0;
    }
  }

  handleErrorMessage = videos => {
    if (!videos.length) {
      return this.searchOffset < VMS_SEARCH_OFFSET ? MMPLAYER_ERRORS.NO_DATA : MMPLAYER_ERRORS.NO_MORE_VIDEOS;
    }
    return null;
  };

  clearSelectedVideoData = () => {
    this.setState({
      selectedVideoData: null,
    });
  };

  searchVmsVideos = () => {
    const { property, startLoading, finishLoading, userOrgId } = this.props;
    const { searchQuery, videos } = this.state;
    this.currentSearchRequest = searchQuery;
    startLoading();
    const searchProperty = getSearchProperty(userOrgId, property);
    EditorNetworkService.searchVmsVideos({
      vmsProperty: transformCmsPropertyToVmsProperty({ property: searchProperty }),
      searchQuery,
      searchLimit: this.searchLimit,
      searchOffset: this.searchOffset,
    }).then(response => {
      this.setState({
        videos: this.searchOffset >= VMS_SEARCH_OFFSET && this.currentSearchRequest === searchQuery
          ? [...videos, ...response.videos] : response.videos,
        selectedVideoData: null,
        error: this.handleErrorMessage(response.videos),
      }, finishLoading);
    }).catch(() => {
      this.setState({
        videos: [],
        selectedVideoData: null,
        error: MMPLAYER_ERRORS.SEARCH_ERROR,
      }, finishLoading);
    });
  };

  loadMoreVideos = () => {
    this.searchOffset += VMS_SEARCH_OFFSET;
    this.searchVmsVideos();
  };

  onUrlInputKeyDown = e => {
    const { mmPlayerData, searchMode } = this.state;
    if (isEnterPressed(e) && mmPlayerData) {
      this.onAdd(mmPlayerData, { pluginMode: searchMode });
    }
  };

  onInputChange = e => {
    const previewHtml = e.target.value;
    this.setState({
      previewHtml,
      error: null,
    }, this.getMMPlayerVideoData(previewHtml));
  };

  onSearchInputKeyDown = e => {
    const { searchQuery } = this.state;
    if (isEnterPressed(e) && searchQuery) {
      this.searchVmsVideos();
    }
  };

  onSearchQueryChange = e => this.setState({ searchQuery: e.target.value });

  onSearchInputClear = () => this.setState({ error: null, videos: [] });

  onGridVideoClick = video => {
    const { startLoading, finishLoading, property, userOrgId, currentTenant } = this.props;
    startLoading();
    const { videos } = this.state;
    const contentId = video.payload_id;
    const orgId = getOrgIdByTenant(currentTenant, userOrgId);
    const videoEmbedUrl = EditorServiceDataProvider.getVmsMiniOEmbedEndpoint({
      orgId,
      contentId,
    });
    getMiniPlayerOembedDataByProxy(videoEmbedUrl, contentId, property.slug).then(response => {
      this.setState({
        selectedVideoData: {
          ...response,
          payload_id: video.payload_id,
        },
        videos: videos.map(videoItem => (videoItem.error ? { ...videoItem, error: null } : videoItem)),
        error: null,
      }, finishLoading);
    }).catch(() => {
      this.setState({
        selectedVideoData: null,
        videos: videos.map(videoItem => (videoItem.payload_id === video.payload_id
          ? { ...videoItem, error: MMPLAYER_ERRORS.VIDEO_ERROR } : { ...videoItem, error: null })),
      }, finishLoading);
    });
  };

  getFooterPluginButtonsProps = () => {
    const { searchMode, selectedVideoData, mmPlayerData, previewHtml } = this.state;
    return searchMode === MMPLAYER_SEARCH_MODES.SEARCH ? {
      onAddClick: this.onAdd,
      isAddDisabled: selectedVideoData === null,
    } : {
      onAddClick: this.onAdd,
      isAddDisabled: mmPlayerData === null || previewHtml === '',
    };
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { searchMode, selectedVideoData, mmPlayerData } = this.state;
    return searchMode === MMPLAYER_SEARCH_MODES.SEARCH ? onAdd(selectedVideoData, { pluginMode: searchMode }) : onAdd(mmPlayerData, { pluginMode: searchMode });
  };

  searchModeOptionChange = newSearchMode => this.setState({ searchMode: newSearchMode });

  shouldRenderLoadMore = () => {
    const { videos, searchQuery, error } = this.state;
    return videos.length && searchQuery && error !== MMPLAYER_ERRORS.NO_MORE_VIDEOS;
  };

  renderLoadMore = () => {
    return this.shouldRenderLoadMore() ? (
      <div css={css(getStylesObject({}).loadMoreButton)}>
        <TextButton content={MMPLAYER_LOAD_MORE_TEXT} onClick={this.loadMoreVideos} />
      </div>
    ) : null;
  };

  renderRadioGroup = () => {
    const { searchMode } = this.state;
    return (
      <div css={css(getEmbedStylesObject().radioGroup)}>
        <RadioGroup
          items={MMPLAYER_RADIO_OPTIONS}
          checkedValue={searchMode}
          onCheckChanged={this.searchModeOptionChange}
          disabled={false}
          orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        />
      </div>
    );
  };

  renderEmbedPreview = () => {
    const { previewHtml } = this.state;
    return (
      <Fragment>
        <Input
          value={previewHtml}
          placeholder={ENTER_MMPLAYER_EMBED_CODE_PLACEHOLDER}
          style={pluginInputStyle}
          onChange={this.onInputChange}
          onKeyDown={this.onUrlInputKeyDown}
          multiline
          autoHeight
        />
        {this.getPreviewComponent()}
      </Fragment>
    );
  };

  renderSearchPreview = () => {
    const { searchQuery, videos, selectedVideoData, error } = this.state;
    const { header, errorStyles } = getStylesObject();
    return (
      <Fragment>
        <div css={css(header)}>
          <SearchInput
            value={searchQuery}
            placeholder={MMPLAYER_SEARCH_PLACEHOLDER}
            сlearable
            variables={{ wrapperWidth: '100%', width: '100%' }}
            onChange={this.onSearchQueryChange}
            onKeyDown={this.onSearchInputKeyDown}
            onClear={this.onSearchInputClear}
          />
        </div>
        <div>
          {videos.length ? (
            <VideosList
              videos={videos}
              selectedVideoData={selectedVideoData}
              onGridVideoClick={this.onGridVideoClick}
              onSelectedVideoClick={this.clearSelectedVideoData}
            />
          ) : null}
          {error ? (
            <Text
              type={Text.TEXT_TYPES.PARAGRAPH_L}
              css={css(errorStyles)}
            >
              {error}
            </Text>
          ) : null}
          {this.renderLoadMore()}
        </div>
      </Fragment>
    );
  };

  getMMPlayerVideoData = previewHtml => {
    const { startLoading, finishLoading, property } = this.props;
    startLoading();
    if (this.isValidMMPlayerEmbedCode(previewHtml)) {
      if (previewHtml.includes('playerId')) {
        const { playerId, contentId } = extractMMPlayerFieldsFromEmbed(previewHtml);
        const videoEmbedUrl = EditorServiceDataProvider.getVmsOEmbedEndpoint({
          playerId,
          videoId: contentId,
        });
        getMMPlayerOembedDataByProxy(videoEmbedUrl, playerId, contentId, property.slug).then(response => {
          this.setState({
            mmPlayerData: response,
            error: null,
          }, finishLoading);
        }).catch(() => {
          this.setState({
            mmPlayerData: null,
            error: MMPLAYER_ERRORS.VIDEO_ERROR,
          }, finishLoading);
        });
      } else {
        const { orgId, contentId, subId } = extractMiniPlayerFieldsFromEmbed(previewHtml);
        const videoEmbedUrl = EditorServiceDataProvider.getVmsMiniOEmbedEndpoint({
          orgId,
          contentId,
          subId,
        });
        getMiniPlayerOembedDataByProxy(videoEmbedUrl, contentId, property.slug).then(response => {
          this.setState({
            mmPlayerData: response,
            error: null,
          }, finishLoading);
        }).catch(() => {
          this.setState({
            mmPlayerData: null,
            error: MMPLAYER_ERRORS.VIDEO_ERROR,
          }, finishLoading);
        });
      }
    } else {
      finishLoading();
      this.setState({ mmPlayerData: null });
    }
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { previewHtml, mmPlayerData } = this.state;
    if (previewHtml === '' || isLoading) {
      return null;
    }
    return mmPlayerData && this.isValidMMPlayerEmbedCode(previewHtml)
      ? <div dangerouslySetInnerHTML={{ __html: previewHtml }} css={css(getEmbedStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_BROKEN_URL_TEXT} />;
  };

  isValidMMPlayerEmbedCode = previewHtml => {
    return previewHtml.trimStart().startsWith('<div')
      && previewHtml.trimEnd().endsWith('</div>')
      && previewHtml.includes('contentId')
      && (previewHtml.includes('playerId') || previewHtml.includes('orgId'));
  };

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { searchMode } = this.state;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {this.renderRadioGroup()}
          {searchMode === MMPLAYER_SEARCH_MODES.EMBED ? this.renderEmbedPreview() : this.renderSearchPreview()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
          onCancelClick={onCancel}
          {...this.getFooterPluginButtonsProps()}
        />
      </Plugin.Container>
    );
  }
}

export const MMPlayerEmbedBlock = props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const embedBlockProps = { ...props, className: 'mplayer-video', loading };
  return <MMPlayerBlock {...embedBlockProps} />;
};

const MMPlayerEmbedOverview = ({ value }) => {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <MMPlayerEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>{value.caption}</Text>
    </Plugin.OverviewBlock>
  );
};

export const MMPlayerEmbed = {
  getPluginTopBarButtonIcon: props => (<MMPlayerEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<MMEmbedPanelComponent {...props} />),
  getPluginBlock: props => <MMPlayerEmbedBlock {...props} />,
  getPluginOverviewBlock: props => (<MMPlayerEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.MM_PLAYER,
};
