import { useEffect } from 'react';
import { Filter, Types } from '@ftbpro/mm-admin-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { FILTER_VARIABLES } from './navigationFilters.styles';
import { organizationSelector } from '../../store/organization/organization.selector';
import { setCurrentTenant, resetAvailableOrganizations } from '../../store/organization/organizationSlice';

const PLACEHOLDER = 'Tenant';

export const TenantFilter = () :JSX.Element => {
  const dispatch = useDispatch();
  const currentTenant = useSelector(organizationSelector.currentTenant);
  const availableTenants = useSelector(organizationSelector.availableTenants);
  const preSelectedTenant = useSelector(navigationFiltersSelector.preSelectedTenant);
  const product = useSelector(navigationFiltersSelector.product);
  const disabled = useSelector(navigationFiltersSelector.disableTenantSelection);

  useEffect(() => {
    if (preSelectedTenant && availableTenants.includes(preSelectedTenant)) {
      dispatch(setCurrentTenant(preSelectedTenant));
    }
    if (!currentTenant) {
      dispatch(setCurrentTenant(availableTenants[0]));
    }
  }, [availableTenants, preSelectedTenant]);

  const onTenantChange = (e: Types.ClickEventType, { value: tenant } :{value: string | number}) => {
    if (currentTenant !== tenant) {
      dispatch(setCurrentTenant(tenant));
      dispatch(resetAvailableOrganizations({ product }));
    }
    return null;
  };

  return (
    <Filter
      placeholder={PLACEHOLDER}
      items={availableTenants}
      disabled={disabled}
      variables={FILTER_VARIABLES}
      onSelectedChange={onTenantChange}
      selectedValue={currentTenant}
    />
  );
};
