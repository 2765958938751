import { Icon } from '@ftbpro/mm-admin-ui-components';
import { CrowdSignalIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../../Plugin/Plugin';

export const CrowdSignalEmbedOverview = () => {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };

  return (
    <Plugin.OverviewBlock>
      <Icon
        icon={CrowdSignalIcon}
        width={32}
        height={32}
        style={overviewIconStyle}
      />
    </Plugin.OverviewBlock>
  );
};
