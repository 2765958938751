export enum GlobalRole {
  MegaAdmin = 'mega_admin',
}

export enum HQRole {
  AdServerManager = 'ad_server_admin',
  AdServerViewer = 'ad_server_viewer',
  AdsTxtAdmin = 'ads_txt_admin',
  AnalyticsDisplayMonetizationReportsBuilderTenant = 'display_monetization_reports_builder_tenant',
  AnalyticsPublisherExternalDemand = 'hq_external_demand_dash',
  AutoBidEditor = 'auto_bid_editor',
  AutoBidViewer = 'auto_bid_viewer',
  AutoBidManager = 'auto_bid_manager',
  MarketplaceAdmin = 'marketplace_admin',
  MarketplaceEditor = 'marketplace_editor',
  MarketplaceViewer = 'marketplace_viewer',
  MontiAdmin = 'monti_admin',
  PromotionsVideoManager = 'pm_video_manager',
  PromotionsWidgetManager = 'pm_widget_manager',
  PromotionsViewer = 'promotion_viewer',
  SitePolicyManager = 'site_policy_manager',
  SitePolicyViewer = 'site_policy_viewer',
  SeoManager = 'seo_manager',
  SeoGoneAdmin = 'seo_gone_admin',
  SetupManager = 'setup_manager',
  ExternalUserAdmin = 'external_user_admin',
  InternalUserAdmin = 'internal_user_admin',
  InjectionsManager = 'injections_manager',
  VMSAdmin = 'vms_admin',
  VMSBulk = 'vms_bulk',
  ExternalAuditViewer = 'external_audit_viewer',
  InternalAuditViewer = 'internal_audit_viewer',
  VersionManagement = 'vms_version_manager',
  OnboardingAdmin = 'onboarding_admin',
  ExperimentsManager = 'experiments_manager',
  PartnerAccountManager = 'partner_account_manager',
  PartnerManagementAdmin = 'partner_management_admin',
  VMSSspMaker = 'player_SSPmaker',
  VMSAbTest = 'player_ABtest',
  QueryBuilderViewer = 'hq_reports_builder_viewer',
  AnalyticsDisplayMonetizationReportsBuilder = 'display_monetization_reports_builder',
  AnalyticsAdmin = 'analytics_admin',
  RoleAdmin = 'role_admin',
  Targeting = 'vms_targeting',
  DataAlertsManager = 'data_alerts_manager',
  MonetizationAdmin = 'mntz-admin',
  UpsmmDashboards = 'upsmmdashboards',
}
