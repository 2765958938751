import { Component, Fragment, useEffect, useRef, useState } from 'react';
import { Filter, Icon, Input, RadioGroup, SearchInput, TextButton } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { TwitterIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../Plugin/Plugin';
import { EmbedBlock } from '../shared/EmbedBlock';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

import { getOembedData } from '../../services/oembed/oEmbedApiProvider';
import { TwitterServices } from '../../services/twitterServices';

import { overviewBlockText } from '../../utils/blockList.utils';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../../utils/plugins.utils';

import {
  TWITTER_OEMBED_PREFIX,
  TWEET_SEARCH_PLACEHOLDER,
  EMBED_URL_BROKEN_TEXT,
  TWITTER_SOURCE_TYPES,
  TWITTER_DROPDOWN_OPTIONS,
  TWITTER_DROPDOWN_OPTION_TO_SORT_OPTION,
  TWITTER_ERROR_MESSAGES,
  TWEET_SEARCH_COUNT,
  LOAD_MORE_TEXT,
  MAX_TWEETS_COUNT,
  TWITTER_RADIO_OPTIONS,
} from './twitter.constants';

import { getStylesObject } from './twitter.styles';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';

const TWITTER_URL_PREVIEW_BLOCK_ID = 'twitter-preview-url';
const TWITTER_SEARCH_PREVIEW_BLOCK_ID = 'twitter-preview-search';
const TWEET_URL_PLACEHOLDER = urlInputPlaceholderGenerator('Twitter');

// Element-Panel Top Bar Button
export const TwitterEmbedTopBarIcon = props => {
  return (
    <Icon icon={TwitterIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component
export class TwitterEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.TWITTER;
    this.state = {
      searchMode: TWITTER_SOURCE_TYPES.URL,
      searchQuery: '',
      searchQueryCache: '',
      searchUrls: [],
      searchTweetData: null,
      searchSelectedIndex: null,
      searchSorting: TWITTER_DROPDOWN_OPTIONS.POPULAR,
      maxId: null,
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      urlTweetData: null,
      shouldShowLoadMore: true,
      error: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.TWITTER;
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchMode } = this.state;
    const didSourceChange = searchMode !== prevState.searchMode;
    if (didSourceChange) {
      const nodeId = searchMode === TWITTER_SOURCE_TYPES.URL ? TWITTER_URL_PREVIEW_BLOCK_ID : TWITTER_SEARCH_PREVIEW_BLOCK_ID;
      this.loadTweets(null, nodeId);
    }
  }

  onSortingChange = (e, { value }) => {
    const { searchQuery, searchSorting } = this.state;
    if (value !== searchSorting) {
      this.setState({
        searchUrls: [],
        searchTweetData: null,
        searchSorting: value,
        maxId: null,
      }, () => { if (searchQuery) { this.searchTweets(); } });
    }
    return false;
  };

  onInputChange = (e, field) => {
    this.setState({ [field]: e.target.value });

    if (field === TWITTER_SOURCE_TYPES.URL) {
      if (this.isValidTwitterUrl(e.target.value)) {
        this.getUrlOembedData(e.target.value);
      }
    }

    if (field === TWITTER_SOURCE_TYPES.SEARCH) {
      this.setState({ shouldShowLoadMore: false });
    }
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { searchMode, urlTweetData, searchTweetData, searchSelectedIndex } = this.state;
    if (searchMode === TWITTER_SOURCE_TYPES.URL) {
      onAdd(urlTweetData, { pluginMode: searchMode });
    }
    if (searchMode === TWITTER_SOURCE_TYPES.SEARCH) {
      onAdd(searchTweetData[searchSelectedIndex], { pluginMode: 'SEARCH' });
    }
  };

  onInputKeyDown = (e, field) => {
    const { urlTweetData } = this.state;
    if (field === TWITTER_SOURCE_TYPES.URL && isEnterPressed(e) && urlTweetData) {
      this.onAdd();
    }

    if (field === TWITTER_SOURCE_TYPES.SEARCH && isEnterPressed(e)) {
      this.searchTweets(e.target.value);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    const oEmbedEndPoint = TWITTER_OEMBED_PREFIX + encodeURI(url.replace('x.com', 'twitter.com'));
    getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.TWITTER, true)
      .then(urlTweetData => {
        finishLoading();
        this.setState({
          urlTweetData,
        }, () => {
          this.loadTweets(null, TWITTER_URL_PREVIEW_BLOCK_ID);
        });
      })
      .catch(() => {
        finishLoading();
        this.setState({ urlTweetData: null });
      });
    this.setState({ urlTweetData: null });
  };

  getListOembedData = urls => {
    const { startLoading, finishLoading } = this.props;
    startLoading();
    const oembedPromises = urls.map(url => {
      if (this.isValidTwitterUrl(url)) {
        const oEmbedEndPoint = TWITTER_OEMBED_PREFIX + encodeURI(url);
        return getOembedData(oEmbedEndPoint, url, BLOCK_TYPES.TWITTER, true)
          .then(urlTweetData => {
            return urlTweetData;
          })
          .catch(() => {
            finishLoading();
            return null;
          });
      }
      return null;
    });

    Promise.all(oembedPromises).then(results => {
      this.setState({ searchTweetData: results }, () => this.loadTweets(null, TWITTER_SEARCH_PREVIEW_BLOCK_ID));
    });
  };

  setSearchItemSelected = index => {
    const { searchSelectedIndex } = this.state;
    return index === searchSelectedIndex ? this.setState({ searchSelectedIndex: null }) : this.setState({ searchSelectedIndex: index });
  };

  resetSearchOnError = error => {
    this.setState({
      searchUrls: [],
      searchTweetData: null,
      error,
      maxId: null,
      shouldShowLoadMore: true,
    });
  };

  searchTweets = async () => {
    const { startLoading, finishLoading, property } = this.props;
    const { searchQuery, searchQueryCache, searchSorting, searchUrls, maxId } = this.state;
    const shouldApplyMaxId = searchQuery === searchQueryCache && !!maxId;

    startLoading();

    if (searchQuery) {
      try {
        const searchResponse = await TwitterServices.searchTweets({
          searchQuery,
          searchSorting: TWITTER_DROPDOWN_OPTION_TO_SORT_OPTION[searchSorting],
          maxId: shouldApplyMaxId && maxId,
          searchCount: shouldApplyMaxId ? TWEET_SEARCH_COUNT + 1 : TWEET_SEARCH_COUNT,
          property: property.slug,
        });

        // empty statuses array
        if (!searchResponse.length) {
          // true = loading 1st time; false = loading more tweets;
          if (!maxId) {
            this.resetSearchOnError(TWITTER_ERROR_MESSAGES.NO_DATA);
          } else {
            this.setState({ error: TWITTER_ERROR_MESSAGES.NO_MORE_TWEETS, shouldShowLoadMore: false });
          }
        } else {
          let newMaxId = null;
          let searchUrlsResponse = searchResponse.map(tweet => {
            if (!newMaxId || tweet.id < newMaxId) {
              newMaxId = tweet.id;
            }
            return `https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`;
          });

          searchUrlsResponse = shouldApplyMaxId ? [...new Set([...searchUrls, ...searchUrlsResponse])] : searchUrlsResponse;

          const notMaxTweetCount = searchUrlsResponse.length < MAX_TWEETS_COUNT;
          const tweetCountChanged = searchUrlsResponse.length !== searchUrls.length;

          if (shouldApplyMaxId) {
            // if loaded more tweets by same params
            this.setState({
              searchUrls: searchUrlsResponse,
              maxId: newMaxId,
              error: notMaxTweetCount && tweetCountChanged ? null : TWITTER_ERROR_MESSAGES.NO_MORE_TWEETS,
              shouldShowLoadMore: notMaxTweetCount,
            }, () => this.getListOembedData(searchUrlsResponse));
          } else {
            // if loaded tweets by new param
            this.setState({
              searchUrls: searchUrlsResponse,
              maxId: newMaxId,
              error: null,
              shouldShowLoadMore: notMaxTweetCount,
            }, () => this.getListOembedData(searchUrlsResponse));
          }
        }
      } catch (e) {
        this.resetSearchOnError(TWITTER_ERROR_MESSAGES.SEARCH_ERROR);
      }
    } else {
      this.resetSearchOnError(TWITTER_ERROR_MESSAGES.NO_SEARCH_QUERY);
    }
    this.setState({ searchQueryCache: searchQuery, searchSelectedIndex: null });
    return finishLoading();
  };

  loadMoreTweets = () => this.searchTweets();

  loadTweets = async (callback, nodeId) => {
    try {
      await TwitterServices.loadTweets(callback, nodeId);
    } catch (e) {
      this.resetSearchOnError(TWITTER_ERROR_MESSAGES.OEMBED_ERROR);
    }
  };

  clearInput = field => {
    this.setState({ [field]: '' });
    return field === TWITTER_SOURCE_TYPES.SEARCH
      ? this.setState({
        searchUrls: [],
        searchTweetData: null,
        searchSelectedIndex: null,
        maxId: null,
        error: null,
      }) : this.setState({ urlTweetData: null, error: null });
  };

  isValidTwitterUrl = url => {
    const xTwitterPattern = /https?:\/\/x\.com\/.*|x\.com\/.*/;
    return url && (xTwitterPattern.test(url) || url.includes('twitter')) && url.includes('status');
  };

  searchModeOptionChange = newSearchMode => this.setState({ searchMode: newSearchMode });

  isAddDisabled = () => {
    const { searchMode, urlTweetData, url, searchSelectedIndex, searchUrls } = this.state;
    return searchMode === TWITTER_SOURCE_TYPES.URL ? (urlTweetData === null || url === '') : (!searchUrls.length || searchSelectedIndex === null);
  };

  shouldRenderLoadMore = () => {
    const { searchTweetData, shouldShowLoadMore, error } = this.state;
    return shouldShowLoadMore && !error && searchTweetData && searchTweetData.length < MAX_TWEETS_COUNT;
  };

  renderRadioGroup = () => {
    const { searchMode } = this.state;
    return (
      <div css={css(getStylesObject({}).radioGroup)}>
        <RadioGroup
          items={TWITTER_RADIO_OPTIONS}
          checkedValue={searchMode}
          onCheckChanged={this.searchModeOptionChange}
          disabled={false}
          orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        />
      </div>
    );
  };

  renderSearchPreview = () => {
    const { searchTweetData, searchQuery, searchSorting, searchUrls, searchSelectedIndex, error } = this.state;
    const { header, previewItem, filterVariables, filter } = getStylesObject({ isTweetSelected: false });
    return (
      <Fragment>
        <div id={TWITTER_SEARCH_PREVIEW_BLOCK_ID}>
          <div css={css(header)}>
            <SearchInput
              value={searchQuery}
              placeholder={TWEET_SEARCH_PLACEHOLDER}
              shouldFocus
              сlearable
              onChange={e => this.onInputChange(e, TWITTER_SOURCE_TYPES.SEARCH)}
              onKeyDown={e => this.onInputKeyDown(e, TWITTER_SOURCE_TYPES.SEARCH)}
              onClear={() => this.clearInput(TWITTER_SOURCE_TYPES.SEARCH)}
            />
            <div css={css(filter)}>
              <Filter
                items={Object.values(TWITTER_DROPDOWN_OPTIONS)}
                disabled={!searchQuery}
                variables={filterVariables}
                selectedValue={searchSorting}
                onSelectedChange={this.onSortingChange}
              />
            </div>
          </div>
          <div>
            {searchTweetData && searchTweetData.map((tweetData, index) => {
              return (
                <div
                  key={searchUrls[index]}
                  css={css(previewItem)}
                >
                  {this.renderSearchPreviewTweet(searchUrls[index], tweetData)}
                  <div
                    css={css(getStylesObject({ isTweetSelected: index === searchSelectedIndex }).cursorCatcher)}
                    onClick={() => { this.setSearchItemSelected(index); }}
                  />
                </div>
              );
            })}
            {this.renderLoadMore()}
            {error ? <Plugin.ErrorMsgComponent text={error} /> : null}
          </div>
        </div>
      </Fragment>
    );
  };

  renderUrlPreview = () => {
    const { url, urlTweetData } = this.state;
    const { input } = getStylesObject({});
    return (
      <Fragment>
        <div id={TWITTER_URL_PREVIEW_BLOCK_ID}>
          <Input
            value={url}
            placeholder={TWEET_URL_PLACEHOLDER}
            autoFocus
            сlearable
            style={input}
            onChange={e => this.onInputChange(e, TWITTER_SOURCE_TYPES.URL)}
            onKeyDown={e => this.onInputKeyDown(e, TWITTER_SOURCE_TYPES.URL)}
            onClear={() => this.clearInput(TWITTER_SOURCE_TYPES.URL)}
          />
          { this.renderUrlPreviewTweet(url, urlTweetData) }
        </div>
      </Fragment>
    );
  };

  renderTweet(html) {
    const { url, searchMode } = this.state;
    const tweetBody = <div dangerouslySetInnerHTML={{ __html: html }} css={css(getStylesObject({}).embed)} />; //eslint-disable-line
    const isValidAndHasHtml = html && this.isValidTwitterUrl(url);
    if (searchMode === TWITTER_SOURCE_TYPES.URL) {
      return isValidAndHasHtml ? tweetBody : <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
    }
    return html ? tweetBody : null;
  }

  renderUrlPreviewTweet(url, tweetData) {
    const { isLoading } = this.props;
    if (!url || isLoading) {
      return null;
    }
    return this.renderTweet(tweetData ? tweetData.html : null);
  }

  renderSearchPreviewTweet(url, tweetData) {
    return url ? this.renderTweet(tweetData ? tweetData.html : null) : null;
  }

  renderLoadMore = () => {
    return this.shouldRenderLoadMore() ? (
      <div css={css(getStylesObject({}).loadMoreButton)}>
        <TextButton content={LOAD_MORE_TEXT} onClick={this.loadMoreTweets} />
      </div>
    ) : null;
  };

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { searchMode } = this.state;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {this.renderRadioGroup()}
          {searchMode === TWITTER_SOURCE_TYPES.URL ? this.renderUrlPreview() : this.renderSearchPreview()}
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={this.isAddDisabled()}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component
export const TwitterBlock = props => {
  const { data } = props;
  const { key } = data;
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  useEffect(() => {
    async function fetchTwitterData() {
      await TwitterServices.loadTweets(setLoading(false), data.key);
    }
    fetchTwitterData();
  }, [key]);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function TwitterEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <TwitterEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data
export const twitterEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<TwitterEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<TwitterEmbedPanelComponent {...props} />),
  getPluginBlock: props => <TwitterBlock {...props} />,
  getPluginOverviewBlock: props => (<TwitterEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.TWITTER,
};
