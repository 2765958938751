import { useState } from 'react';
import { css } from '@emotion/react';
import { Text } from '@ftbpro/mm-admin-ui-components';

import { splitArrayIntoChunks, insertPreviewRow } from '../../utils/plugins.utils';
import { getUrlFromImageResponseObject, IMAGE_VIEW_TYPES } from './plugins.utils';

import { PREVIEW_PREFIX } from './plugins.constants';

import { getStylesObject } from './styles/pluginGridWithPreview.styles';

/* CURRENTLY SUPPORTS 3 COLUMNS GRID */

export const PluginGridWithPreview = ({
  images,
  columns = 3,
  selectedImage,
  blockType,
  removePreviewImage,
  onImageChoose,
  onGridImageClick,
  onImageError,
}) => {
  const [loadedIndicators, setLoadedIndicators] = useState({});
  const { grid } = getStylesObject({ columns });

  const onImageClick = image => {
    return selectedImage && image.id === `${PREVIEW_PREFIX}${selectedImage.id}` ? onImageChoose(image) : onGridImageClick(image);
  };

  const generateGridWithImage = items => insertPreviewRow({ items, id: selectedImage.id });

  const handleImageLoad = image => setLoadedIndicators({ ...loadedIndicators, [image.id]: true });

  const renderPlaceholder = isPreviewImage => <div css={css(getStylesObject({ isPreviewImage }).placeholder)} />;

  const renderPreviewImageContainer = image => {
    const { imageHolder, imageItem, imageCaption } = getStylesObject({
      isPreviewImage: true,
      loading: !loadedIndicators[image.id],
    });
    return (
      <div css={css(imageHolder)} onDoubleClick={() => onImageClick(image)}>
        {!loadedIndicators[image.id] ? renderPlaceholder(true) : null}
        <img
          css={css(imageItem)}
          src={selectedImage.previewImageUrl}
          onLoad={() => handleImageLoad(image)}
          alt="previewImage"
        />
        {loadedIndicators[image.id] ? (
          <div css={css(imageCaption)}>
            <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>{image.title}</Text>
          </div>
        ) : null}
      </div>
    );
  };

  const renderImageContainer = image => {
    const isCurrentImageSelected = selectedImage && selectedImage.id === image.id;
    const onClickFunc = isCurrentImageSelected ? removePreviewImage : () => onImageClick(image);
    const { imageHolder, imageItem } = getStylesObject({
      loading: !loadedIndicators[image.id],
      isSelected: isCurrentImageSelected,
    });
    return (
      <div css={css(imageHolder)} onClick={onClickFunc}>
        {!loadedIndicators[image.id] ? renderPlaceholder(false) : null}
        <img
          css={css(imageItem)}
          src={getUrlFromImageResponseObject({ blockType, image, imageViewType: IMAGE_VIEW_TYPES.GRID_IMAGE })}
          onLoad={() => handleImageLoad(image)}
          onError={() => onImageError(image)}
          alt="gridImage"
        />
      </div>
    );
  };

  const renderGridRow = (imagesList, index) => {
    const isPreviewImage = imagesList.length === 1 && imagesList[0].id.indexOf(PREVIEW_PREFIX) !== -1;
    const { row, column } = getStylesObject({ columns: isPreviewImage ? 1 : columns });
    return (
      <div css={css(row)} key={`grid-row-${index}`}>
        {imagesList.map(image => {
          return (
            <div css={css(column)} key={image.id}>
              {isPreviewImage ? renderPreviewImageContainer(image) : renderImageContainer(image)}
            </div>
          );
        })}
      </div>
    );
  };

  const gridItems = splitArrayIntoChunks(images, columns);
  const hasSelectedImage = selectedImage && selectedImage.previewImageUrl;
  const imagesList = hasSelectedImage ? generateGridWithImage(gridItems) : gridItems;
  return (
    <div css={css(grid)}>
      {imagesList.map((chunks, index) => renderGridRow(chunks, index))}
    </div>
  );
};
