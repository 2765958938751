import { Icon } from '@ftbpro/mm-admin-ui-components';
import { LiveBlogIcon } from '@ftbpro/mm-admin-assets';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import {
  LiveBlogEditorType,
  LiveBlogPanelProps,
  TopBarIconProps,
} from './liveBlog.types';
import { LiveBlogOverview } from './components/LiveBlogOverview';
import { LiveBlogBlock } from './components/LiveBlogBlock';
import { LiveBlogPanel } from './components/LiveBlogPanel';

export const liveBlogEmbedPluginData = {
  getPluginTopBarButtonIcon: (props: TopBarIconProps) => (
    <Icon icon={LiveBlogIcon} width={28} height={28} {...props} />
  ),
  getPluginPanelComponent: (props: LiveBlogPanelProps) => (
    <LiveBlogPanel {...props} />
  ),
  getPluginBlock: (props: LiveBlogEditorType) => <LiveBlogBlock {...props} />,
  getPluginOverviewBlock: () => <LiveBlogOverview />,
  pluginBlockType: BLOCK_TYPES.LIVE_BLOG,
};
