export const PREMIUM_EDITORS = [
  'melissa-g',
  'jamie.lisanti',
  'michalsp',
  'sebaseba',
  'omer-ami',
  'samantha-m',
  'ido-cohen',
  'rom-zu',
  'alec-f',
  'noarrr',
  'alex-moeller-si',
  'alex-moeller',
  'marissa-mm',
  'yuvalf',
  'kristen-nelson',
  'marissa-mcd',
  'john.pluym',
  'mitch-goldich',
  'johanna.huybers',
  'lorenzo-arguello',
  'will-laws',
  'clare-brennan',
  'dan-gartland',
  'nick-selbe',
  'zach-koons',
  'andrew-g',
  'jeff-ritter',
  'john-schwarb',
];
