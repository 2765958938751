import { Fragment } from 'react';
import { ErrorPage } from '../ErrorPage';
import {
  BLOCKED_USER_ERROR,
  UNAUTHORIZED_ERROR_TITLE,
  UNAUTHORIZED_ERROR_MESSAGE,
  VOLTAX_LOGO,
} from '../errorPage.constants';
import { useStyles } from '../../../hooks/useStyles';
import { getStylesObject } from './blockedUserErrorPage.styles';
import { ConfigsProvider } from '../../../configs/ConfigsProvider';

export const BlockedUserErrorPage = () => {
  const styles = useStyles(getStylesObject);
  const logoSrc = ConfigsProvider.LOGO_URL;
  return (
    <Fragment>
      <img src={logoSrc} alt={VOLTAX_LOGO} css={styles.logo} />
      <ErrorPage
        title={UNAUTHORIZED_ERROR_TITLE}
        description={BLOCKED_USER_ERROR}
        message={UNAUTHORIZED_ERROR_MESSAGE}
      />
    </Fragment>
);
};
