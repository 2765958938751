import { DATE_FORMATS, formatDate } from '@ftbpro/mm-admin-core-utils';
import { ConfigsProvider } from 'core/configs/ConfigsProvider';
import { UserData } from 'core/store/user/types';

export function shouldLoadAppcues() {
  return ConfigsProvider.FeatureFlags?.LoadAppcues;
}

export function initAppcues() {
  if (shouldLoadAppcues()) {
    window.AppcuesSettings = { enableURLDetection: true };

    const appcuesScriptQueue = document.createElement('script');
    appcuesScriptQueue.innerText = `
      (function() {
        if (!window.AppcuesReady) {
          window.AppcuesReady = function(callback) {
            callback && window.AppcuesReady.q.push(callback);
            if (window.Appcues) {
              while (window.AppcuesReady.q.length) {
                cb = window.AppcuesReady.q.shift();
                if (typeof cb === 'function') cb();
              }
            }
          };
          window.AppcuesReady.q = [];
        }
      })()
    `;
    appcuesScriptQueue.type = 'text/javascript';
    document.body.prepend(appcuesScriptQueue);

    const appcuesScript = document.createElement('script');
    appcuesScript.type = 'text/javascript';
    appcuesScript.async = true;
    appcuesScript.defer = true;
    appcuesScript.src = '//fast.appcues.com/114698.js';
    document.body.appendChild(appcuesScript);
  }
}

export function identifyUserInAppcues(userData: UserData, properties: string[]) {
  const { userDetails, userRoles, orgId } = userData;
  const { username, name, userId, email, registrationDate } = userDetails;
  window.AppcuesReady?.(() => {
    window.Appcues?.identify(userId, {
      username,
      name,
      email,
      roles: userRoles ? userRoles?.join(',') : '',
      organization: orgId,
      userRegistrationDate: registrationDate && formatDate(registrationDate, DATE_FORMATS.ISO_DATE),
      properties: properties.join(','),
    });
  });
}
