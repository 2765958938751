import { Fragment } from 'react';
import { css } from '@emotion/react';
import {
  Toggle,
  Text,
  CssTooltip,
} from '@ftbpro/mm-admin-ui-components';
import { fullBleedImageTogglerStyles } from './fullBleedImageToggler.styles';
import {
  FULL_BLEED_IMAGE_FURTHER_INFO,
  FULL_BLEED_IMAGE_TITLE,
  FULL_BLEED_IMAGE_TOOLTIP_CONTENT,
} from './FullBleedImageToggler.constants';

export const FullBleedImageToggler = ({
  checked,
  onToggleChange,
}) => {
  return (
    <Fragment>
      <div css={css(fullBleedImageTogglerStyles.controlContainer)}>
        <div css={css(fullBleedImageTogglerStyles.titleContainer)}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>
            {FULL_BLEED_IMAGE_TITLE}
          </Text>
          <CssTooltip
            content={FULL_BLEED_IMAGE_TOOLTIP_CONTENT}
            style={fullBleedImageTogglerStyles.toggle}
            multiline
            variables={{ tooltipMultilineWhiteSpace: 'pre', tooltipMultilineWidth: 'auto' }}
          />
        </div>
        <Toggle
          onChange={onToggleChange}
          checked={checked}
        />
      </div>
      <Text color={fullBleedImageTogglerStyles.furtherInfo.color}>
        {FULL_BLEED_IMAGE_FURTHER_INFO}
      </Text>
    </Fragment>
  );
};
