import { Component } from 'react';
import { Plugin } from '../Plugin/Plugin';

export class HtmlEmbedBlock extends Component {
  hasInnerHtml = () => {
    const { data } = this.props;
    return data && data.value.embedCodeHTMLString;
  };

  render() {
    const { className, shouldPreventEdit, shouldPreventDelete, onMediaDeleted, onMediaEdited, onMediaEditCanceled, isEditing, loading, blockRef } = this.props;
    return (
      this.hasInnerHtml()
        ? (
          <Plugin.Block
            loading={loading}
            shouldPreventEdit={shouldPreventEdit}
            shouldPreventDelete={shouldPreventDelete}
            onDelete={onMediaDeleted}
            onEdit={onMediaEdited}
            onCancel={onMediaEditCanceled}
            isEditing={isEditing}
          >
            <div
              dangerouslySetInnerHTML={{ __html: this.props.data.value.embedCodeHTMLString }}  /* eslint-disable-line */
              ref={blockRef}
              className={className}
            />
          </Plugin.Block>
        )
        : null
    );
  }
}
