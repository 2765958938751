import { Component, Fragment } from 'react';
import { Icon, SearchInput, TextButton } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { ImagnIcon } from '@ftbpro/mm-admin-assets';
import { ImageBlock, ImageBlockOverview } from '../shared/ImageBlock';
import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';
import {
  CROP_ASPECT_TYPES, formatImgLinkObject,
  getCropAspectName,
  getInitialPercentageCrop, substringImageCaptionAndCredit, validateLinkableImage,
} from '../../../services/imageServices/imageService.utils';
import {
  ERROR_MESSAGES,
  RESPONSE_ERRORS,
  PANEL_MODES,
  PLUGIN_BUTTON_CHOOSE_TEXT,
  ENTER_SEARCH_PLACEHOLDER,
  LOAD_MORE_TEXT,
  IMAGN_IMAGES_PROVIDER,
} from '../shared/plugins.constants';
import { removeLineBreaks } from '../../utils/inlineText.utils';
import { formatImagnImagesResult, pluginAddButtonTextHandler } from '../../utils/plugins.utils';
import { getStylesObject } from '../shared/styles/gettyAndImagn.styles';
import { Plugin } from '../Plugin/Plugin';
import { PluginGridWithPreview } from '../shared/PluginGridWithPreview';
import { ImagnServices } from '../../services/imagnServices';
import { getUrlFromImageResponseObject, IMAGE_VIEW_TYPES } from '../shared/plugins.utils';
import { CroppingAreaCustomization } from '../../shared/croppingArea/CroppingAreaCustomization';
import { IMAGE_SIZE_TYPES } from '../../../constants/image.constants';
import { ImageDescription } from '../../shared/ImageDescription';
import { FullBleedImageToggler } from '../../shared/FullBleedImageToggler';
import { LinkableImageSettings } from '../../shared/LinkableImageSettings';

// Element-Panel Top Bar Button
const LINKABLE_IMAGE_URL_ERROR = 'URL must start with \'http://\' or \'https://\'';

export const ImagnTopBarIcon = props => {
  return (
    <Icon icon={ImagnIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class ImagnPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.resultsPageNumber = 1;
    this.currentSearchRequest = '';
    this.state = {
      searchQuery: '',
      imagnImagesList: [],
      mode: PANEL_MODES.IMAGN,
      selectedImage: null,
      percentageCrop: { aspect: CROP_ASPECT_TYPES.HORIZONTAL },
      caption: '',
      credit: '',
      alt: '',
      imageLoading: false,
      errorMessage: null,
      noMoreImagesFound: false,
      sizeType: IMAGE_SIZE_TYPES.REGULAR,
      isImageLinkable: !!props.editedBlockData?.value.linkURL,
      linkableImageSettings: {
        targetLink: props.editedBlockData?.value.linkURL || '',
        shouldOpenNewTab: !!props.editedBlockData?.value.linkTargetAttribute,
        shouldNoFollow: !!props.editedBlockData?.value.linkRelAttribute,
      },
      linkableImageLinkError: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.IMAGE;
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.state;
    if (searchQuery !== prevState.searchQuery) {
      this.resultsPageNumber = 1;
    }
  }

  onSearchInputChange = e => {
    const { errorMessage } = this.state;
    const searchQuery = e.target.value;
    this.setState({
      searchQuery,
      errorMessage: errorMessage === ERROR_MESSAGES.NO_SEARCH_QUERY && searchQuery ? null : errorMessage,
    });
  };

  onSearchInputKeyDown = async e => {
    const { searchQuery } = this.state;
    if (e.key.toLowerCase() === 'enter' && searchQuery) {
      await this.searchImagnImages();
    }
  };

  onGridImageClick = image => {
    const { startLoading } = this.props;
    startLoading();
    return this.setSelectedImage({
      ...image,
      previewImageUrl: getUrlFromImageResponseObject({
        blockType: BLOCK_TYPES.IMAGN,
        image,
        imageViewType: IMAGE_VIEW_TYPES.PREVIEW_IMAGE,
      }),
    });
  };

  onImageChoose = () => {
    const { selectedImage, percentageCrop: { aspect } } = this.state;
    this.setState({
      mode: PANEL_MODES.CROP,
      imageLoading: true,
      caption: selectedImage && selectedImage.caption ? substringImageCaptionAndCredit(selectedImage.caption, 500) : '',
      credit: selectedImage && selectedImage.credit ? substringImageCaptionAndCredit(selectedImage.credit, 100) : '',
      percentageCrop: { aspect },
    });
  };

  onImageError = image => {
    const { imagnImagesList } = this.state;
    const { id } = image;
    this.setState({
      imagnImagesList: imagnImagesList.filter(item => item.id !== id),
    });
  };

  setCompletedCrop = percentageCrop => {
    this.setState({ percentageCrop });
  };

  onCropAspectTypeChange = type => {
    const percentageCrop = getInitialPercentageCrop(type);
    this.setState({
      percentageCrop,
    });
  };

  onChangeCaption = (e, value) => {
    this.setState({ caption: removeLineBreaks(value) });
  };

  onChangeAlt = (e, value) => {
    this.setState({ alt: removeLineBreaks(value) });
  };

  onCaptionInputKeyDown = e => {
    if (e.key.toLowerCase() === 'enter') {
      this.onAddHandler();
    }
  };

  onImageLoaded = () => {
    const { percentageCrop } = this.state;
    const newCrop = getInitialPercentageCrop(percentageCrop.aspect);
    this.setState({
      percentageCrop: newCrop,
      imageLoading: false,
    });
  };

  onBackButtonClick = () => {
    this.setState({
      mode: PANEL_MODES.IMAGN,
    });
  };

  onAddHandler = () => {
    const { onAdd } = this.props;
    const { isImageLinkable, linkableImageSettings, alt } = this.state;
    const isValidLinkableUrl = isImageLinkable && validateLinkableImage(linkableImageSettings.targetLink);
    if ((!isImageLinkable || isValidLinkableUrl) && alt) {
      this.setLinkableImageLinkError('');
      return onAdd(this.getData());
    }
    return this.setLinkableImageLinkError(LINKABLE_IMAGE_URL_ERROR);
  };

  setImages = imagesResult => {
    const { finishLoading } = this.props;
    const { searchQuery, imagnImagesList } = this.state;
    const imagesFound = imagesResult && imagesResult.item.length;
    if (imagesFound) {
      const images = formatImagnImagesResult(imagesResult.item);
      this.setState({
        imagnImagesList: this.resultsPageNumber !== 1 && this.currentSearchRequest === searchQuery ? [...imagnImagesList, ...images] : images,
        errorMessage: null,
        noMoreImagesFound: false,
      });
    } else if (this.resultsPageNumber === 1) {
      this.setState({ imagnImagesList: [] });
      this.showErrorMessage(ERROR_MESSAGES.NO_DATA);
    } else if (this.resultsPageNumber !== 1) {
      this.setState({
        noMoreImagesFound: true,
      });
    }
    finishLoading();
  };

  setSelectedImage = image => {
    const { finishLoading } = this.props;
    this.setState({
      selectedImage: image,
      caption: image.title,
    });
    finishLoading();
  };

  getData = () => {
    const { selectedImage, percentageCrop, caption, credit, alt, sizeType, isImageLinkable, linkableImageSettings } = this.state;
    const { linkURL, linkTargetAttribute, linkRelAttribute } = formatImgLinkObject({ linkableImageSettings });
    return {
      image: selectedImage,
      crop: percentageCrop,
      aspectRatio: getCropAspectName(percentageCrop.aspect),
      caption,
      credit,
      alt,
      sizeType,
      ...(isImageLinkable && { linkURL, linkTargetAttribute, linkRelAttribute }),
      provider: IMAGN_IMAGES_PROVIDER,
    };
  };

  getFooterPluginButtonsProps = isImagnMode => {
    const { imageLoading, alt } = this.state;
    const { editedBlockData } = this.props;
    return isImagnMode ? {
      onAddClick: this.onImageChoose,
      isAddDisabled: this.isChooseButtonDisabled(),
      addButtonText: PLUGIN_BUTTON_CHOOSE_TEXT,
    } : {
      onAddClick: this.onAddHandler,
      isAddDisabled: imageLoading || !alt,
      addButtonText: pluginAddButtonTextHandler(editedBlockData),
    };
  };

  loadMoreImages = async () => {
    this.resultsPageNumber += 30;
    await this.searchImagnImages();
  };

  removePreviewImage = () => {
    this.setState({
      selectedImage: null,
    });
  };

  showErrorMessage = payload => {
    const { finishLoading } = this.props;
    this.setState({
      errorMessage: payload,
    });
    finishLoading();
  };

  handleImagnError = e => {
    const { finishLoading } = this.props;
    if (e.code === RESPONSE_ERRORS.CODE500) {
      this.showErrorMessage(ERROR_MESSAGES.NO_DATA);
    }
    finishLoading();
  };

  searchImagnImages = async () => {
    const { startLoading, property } = this.props;
    const { searchQuery } = this.state;
    if (searchQuery) {
      startLoading();
      try {
        const searchResult = await ImagnServices.searchImages({
          searchQuery,
          offset: this.resultsPageNumber,
          property: property.slug,
        });
        if (searchResult.code) {
          this.handleImagnError(searchResult);
        } else {
          this.setImages(searchResult.response.payload.results);
        }
      } catch (e) {
        this.handleImagnError(e);
      }
      this.currentSearchRequest = searchQuery;
    } else {
      this.setState({
        errorMessage: ERROR_MESSAGES.NO_SEARCH_QUERY,
      });
    }
  };

  isChooseButtonDisabled = () => {
    const { selectedImage } = this.state;
    return !selectedImage;
  };

  shouldShowLoadMoreButton = () => {
    const { imagnImagesList, noMoreImagesFound, searchQuery } = this.state;
    return imagnImagesList.length && !noMoreImagesFound && searchQuery;
  };

  clearSearchQuery = () => {
    this.setState({
      searchQuery: '',
    });
  };

  renderLoadMoreButton = () => {
    const { loadMoreButton } = getStylesObject();
    return (
      this.shouldShowLoadMoreButton()
        ? (
          <div css={css(loadMoreButton)}>
            <TextButton content={LOAD_MORE_TEXT} onClick={this.loadMoreImages} />
          </div>
        )
        : null
    );
  };

  renderImagnPanel = () => {
    const { errorMessage, noMoreImagesFound } = this.state;
    const ImagnHeader = this.renderImagnHeader;
    const ImagnBody = this.renderImagnBody;
    const LoadMoreButton = this.renderLoadMoreButton;
    return (
      <Fragment>
        <ImagnHeader />
        {errorMessage ? <Plugin.ErrorMsgComponent text={errorMessage} /> : <ImagnBody />}
        <LoadMoreButton />
        {noMoreImagesFound ? <Plugin.ErrorMsgComponent text={ERROR_MESSAGES.NO_MORE_IMAGES} /> : null}
      </Fragment>
    );
  };

  renderImagnHeader = () => {
    const { isLoading } = this.props;
    const { searchQuery } = this.state;
    const { header } = getStylesObject();
    return (
      <div css={css(header)}>
        <SearchInput
          value={searchQuery}
          placeholder={ENTER_SEARCH_PLACEHOLDER}
          disabled={isLoading}
          сlearable
          variables={{ wrapperWidth: '100%', width: '100%' }}
          onChange={this.onSearchInputChange}
          onKeyDown={this.onSearchInputKeyDown}
          onClear={this.clearSearchQuery}
        />
      </div>
    );
  };

  renderImagnBody = () => {
    const { imagnImagesList, selectedImage } = this.state;
    return imagnImagesList.length
      ? (
        <PluginGridWithPreview
          images={imagnImagesList}
          selectedImage={selectedImage}
          onImageError={this.onImageError}
          blockType={BLOCK_TYPES.IMAGN}
          onGridImageClick={this.onGridImageClick}
          onImageChoose={this.onImageChoose}
          removePreviewImage={this.removePreviewImage}
        />
      )
      : null;
  };

  renderImageDescription = () => {
    const { caption, credit, alt } = this.state;
    const { shouldShowAuditAndTags } = this.props;
    return (
      <ImageDescription
        shouldShowAuditAndTags={shouldShowAuditAndTags}
        caption={caption}
        credit={credit}
        alt={alt}
        isCreditEditingEnabled={false}
        onChangeCaption={this.onChangeCaption}
        onChangeAlt={this.onChangeAlt}
        onKeyDown={this.onCaptionInputKeyDown}
      />
    );
  };

  setIsImageLinkable = () => {
    const { isImageLinkable } = this.state;
    this.setState({
      isImageLinkable: !isImageLinkable,
    });
  };

  setLinkableImageSettings =({ targetLink, shouldOpenNewTab, shouldNoFollow }) => {
    this.setState({
      linkableImageSettings: {
        targetLink,
        shouldOpenNewTab,
        shouldNoFollow,
      },
    });
  };

  onLinkableImageDataChange = data => {
    if (validateLinkableImage(data.targetLink)) {
      this.setLinkableImageLinkError('');
    }
    return this.setLinkableImageSettings(data);
  };

  setLinkableImageLinkError=error => {
    this.setState({
      linkableImageLinkError: error,
    });
  };

  renderLinkableImageSettings = () => {
    const { isImageLinkable, linkableImageSettings, linkableImageLinkError } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    return (
      !shouldAllowHorizontalCropOnly && (
        <LinkableImageSettings
          checked={isImageLinkable}
          onToggleChange={this.setIsImageLinkable}
          onLinkableImageDataChange={this.onLinkableImageDataChange}
          linkableImageSettings={linkableImageSettings}
          linkableImageLinkError={linkableImageLinkError}
        />
      )
    );
  };

  renderFullBleedImageTogglerIfNeedTo = () => {
    const { sizeType } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    const shouldRenderFullBleedImageToggler = !shouldAllowHorizontalCropOnly;
    return shouldRenderFullBleedImageToggler ? (
      <FullBleedImageToggler
        checked={sizeType === IMAGE_SIZE_TYPES.FULL_BLEED}
        onToggleChange={() => {
          this.setState({ sizeType: sizeType === IMAGE_SIZE_TYPES.REGULAR
            ? IMAGE_SIZE_TYPES.FULL_BLEED
            : IMAGE_SIZE_TYPES.REGULAR });
        }}
      />
    ) : null;
  };

  renderImageConfigurationIfNeedTo = () => {
    const { selectedImage, imageLoading } = this.state;
    const shouldRenderImageConfiguration = !!selectedImage.previewImageUrl && !imageLoading;
    return shouldRenderImageConfiguration ? (
      <Fragment>
        {this.renderImageDescription()}
        {this.renderLinkableImageSettings()}
        {this.renderFullBleedImageTogglerIfNeedTo()}
      </Fragment>
    ) : null;
  };

  renderCroppingArea = () => {
    const { shouldAllowHorizontalCropOnly } = this.props;
    const { selectedImage, imageLoading, percentageCrop, cropAspectType } = this.state;
    const { editImageContainer } = getStylesObject();
    return (
      <div css={css(editImageContainer)}>
        <CroppingAreaCustomization
          imageLoading={imageLoading}
          hasImage
          src={selectedImage.previewImageUrl}
          percentageCrop={percentageCrop}
          cropAspectType={cropAspectType}
          setCompletedCrop={this.setCompletedCrop}
          onCropAspectTypeChange={this.onCropAspectTypeChange}
          onImageLoaded={this.onImageLoaded}
          shouldAllowHorizontalCropOnly={shouldAllowHorizontalCropOnly}
          backButton
          backButtonOnClick={this.onBackButtonClick}
        />
        {this.renderImageConfigurationIfNeedTo()}
      </div>
    );
  };

  render() {
    const { onCancel } = this.props;
    const { mode, imageLoading } = this.state;
    const isImageMode = mode === PANEL_MODES.IMAGN;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {isImageMode ? this.renderImagnPanel() : this.renderCroppingArea()}
        </Plugin.Content>
        <Plugin.Buttons onCancelClick={onCancel} isCancelDisabled={imageLoading} {...this.getFooterPluginButtonsProps(isImageMode)} />
      </Plugin.Container>
    );
  }
}

// Plugin Data
export const imagnEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<ImagnTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<ImagnPanelComponent {...props} />),
  getPluginBlock: props => <ImageBlock {...props} />,
  getPluginOverviewBlock: props => (<ImageBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.IMAGE,
};
