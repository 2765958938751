import PropTypes from 'prop-types';
import { Text } from '@ftbpro/mm-admin-ui-components';
import { useStyles } from '../../hooks/useStyles';
import { getStylesObject } from './errorPage.styles';
import ErrorImage from '../../assets/error-img.svg';
import { UNAUTHORIZED_ERROR_MESSAGE } from './errorPage.constants';

export const ErrorPage = props => {
  const { title, description, message } = props;
  const styles = useStyles(getStylesObject);
  return (
    <div css={styles.mainContainer}>
      <div css={styles.contentContainer}>
        <img src={ErrorImage} alt="error" css={styles.image} />
        <Text css={styles.title} type={Text.TEXT_TYPES.TITLE_XL}>{title}</Text>
        <div css={styles.description}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>{description}</Text>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L}>{message}</Text>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  message: PropTypes.node,
};

ErrorPage.defaultProps = {
  title: '',
  description: '',
  message: UNAUTHORIZED_ERROR_MESSAGE,
};
