import { isUndefined } from '@ftbpro/mm-admin-core-utils';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { BackgroundTasksCenter } from './BackgroundTasksCenter';
import { BackgroundTasksContext } from './BackgroundTasksContext';
import { backgroundTasksSelector } from './store/backgroundTasks.selector';

export const useBackgroundTasks = () => useContext(BackgroundTasksContext);

export const BackgroundTasksProvider = ({ children }: {
  children: React.ReactNode,
}) => {
  const backgroundTasks = useSelector(backgroundTasksSelector.tasks);

  const isTaskExists = (taskId: string) => {
    return !isUndefined(backgroundTasks[taskId]);
  };

  return (
    <BackgroundTasksContext.Provider
      value={{
        isTaskExists,
      }}
    >
      {children}
      <BackgroundTasksCenter />
    </BackgroundTasksContext.Provider>
  );
};
