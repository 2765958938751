import { useState } from 'react';
import { Chip, Input } from '@ftbpro/mm-admin-ui-components';

import { isEmpty } from '../../../../core/utils/utils';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';

import { MediaLibraryInputWithChipStyle } from './MediaLibraryInputWithChip.style';

const ChipList = props => {
  const { disabled, chipList, name, onRemove } = props;
  return (
    <div style={MediaLibraryInputWithChipStyle.chipsContainer}>
      {
        isEmpty(chipList) ? null
          : chipList.map((text, index) => (
            <Chip
              disabled={disabled}
              key={index}
              text={text}
              icon="remove"
              selected
              onClick={() => onRemove(text, index, chipList, name)}
              style={MediaLibraryInputWithChipStyle.chip}
            />
          ))
      }
    </div>
  );
};

export const MediaLibraryInputWithChip = props => {
  const { disabled, chipList, onRemove, onAdd, style, name, placeholder, labelProps } = props;
  const [inputValue, setInputValue] = useState('');

  const onInputKeyDown = e => {
    if (isEnterPressed(e) && !isEmpty(inputValue) && !isEmpty(inputValue.trim())) {
      if (onAdd) {
        onAdd(inputValue.trim(), chipList, name);
        setInputValue('');
      }
    }
  };

  const onInputChanged = (e, value) => {
    setInputValue(value.toLowerCase());
  };

  const combinedRootStyle = {
    ...MediaLibraryInputWithChipStyle.root,
    ...style,
  };

  return (
    <div style={combinedRootStyle}>
      <Input
        disabled={disabled}
        labelProps={labelProps}
        placeholder={placeholder}
        value={inputValue}
        onChange={onInputChanged}
        onKeyDown={onInputKeyDown}
      />
      <ChipList disabled={disabled} chipList={chipList} onRemove={onRemove} name={name} />
    </div>
  );
};
