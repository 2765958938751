import { Component, Fragment } from 'react';
import { Icon, SearchInput, TextButton } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { MediaLibraryIcon } from '@ftbpro/mm-admin-assets';
import { Plugin } from '../Plugin/Plugin';
import { ImageBlock, ImageBlockOverview } from '../shared/ImageBlock';
import { PluginGridWithPreview } from '../shared/PluginGridWithPreview';
import { CroppingAreaCustomization } from '../../shared/croppingArea/CroppingAreaCustomization';

import { BLOCK_TYPES } from '../../utils/blocksDescriptorGenerator';

import {
  ERROR_MESSAGES,
  PANEL_MODES,
  MEDIA_LIBRARY_EMBED_PROVIDER,
  PLUGIN_BUTTON_CHOOSE_TEXT,
  ENTER_SEARCH_PLACEHOLDER,
  LOAD_MORE_TEXT,
} from '../shared/plugins.constants';

import { removeLineBreaks } from '../../utils/inlineText.utils';
import { pluginAddButtonTextHandler } from '../../utils/plugins.utils';
import {
  CROP_ASPECT_TYPES,
  formatImgLinkObject,
  getCropAspectName,
  getInitialPercentageCrop,
  validateLinkableImage,
} from '../../../services/imageServices/imageService.utils';
import { getUrlFromImageResponseObject, IMAGE_VIEW_TYPES } from '../shared/plugins.utils';

import { getStylesObject } from '../shared/styles/gettyAndImagn.styles';
import { ImageDescription } from '../../shared/ImageDescription';
import { IMAGE_SIZE_TYPES } from '../../../constants/image.constants';
import { FullBleedImageToggler } from '../../shared/FullBleedImageToggler';
import { isEnterPressed } from '../../../../../core/utils/keyboard.utils';
import { LinkableImageSettings } from '../../shared/LinkableImageSettings';
import { MediaLibraryImagesNetworkService } from '../../../../mediaLibrary/images/services/mediaLibraryImagesApiProvider';
import { STATUS_FILTER_VALUES } from '../../../../mediaLibrary/images/components/imagesDashboard/imagesDashboard.constants';

// Element-Panel Top Bar Button
const LINKABLE_IMAGE_URL_ERROR = 'URL must start with \'http://\' or \'https://\'';

export const MediaLibraryEmbedTopBarIcon = props => {
  return (
    <Icon icon={MediaLibraryIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class MediaLibraryEmbedPanelComponent extends Component {
  constructor({ editedBlockData }) {
    super({ editedBlockData });
    this.resultsPageNumber = 1;
    this.currentSearchRequest = '';
    this.state = {
      searchQuery: '',
      mediaLibraryEmbedImagesList: [],
      mode: PANEL_MODES.MEDIA_LIBRARY,
      selectedImage: null,
      percentageCrop: {
        aspect: CROP_ASPECT_TYPES.HORIZONTAL,
      },
      caption: '',
      credit: '',
      alt: '',
      nextPageUrl: '',
      imageLoading: false,
      errorMessage: null,
      sizeType: IMAGE_SIZE_TYPES.REGULAR,
      isImageLinkable: !!editedBlockData?.value.linkURL,
      linkableImageSettings: {
        targetLink: editedBlockData?.value.linkURL || '',
        shouldOpenNewTab: !!editedBlockData?.value.linkTargetAttribute,
        shouldNoFollow: !!editedBlockData?.value.linkRelAttribute,
      },
      linkableImageLinkError: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.IMAGE;
  }

  componentDidUpdate(prevProps, prevState) {
    const { searchQuery } = this.state;
    if (searchQuery !== prevState.searchQuery) {
      this.resultsPageNumber = 1;
    }
  }

   setIsImageLinkable = () => {
    const { isImageLinkable } = this.state;
    this.setState({
      isImageLinkable: !isImageLinkable,
    });
  };

   setLinkableImageSettings =({ targetLink, shouldOpenNewTab, shouldNoFollow }) => {
    this.setState({
      linkableImageSettings: {
        targetLink,
        shouldOpenNewTab,
        shouldNoFollow,
      },
    });
  };

  setLinkableImageLinkError = error => {
    this.setState({
      linkableImageLinkError: error,
    });
  };

  onSearchInputChange = e => {
    const { errorMessage } = this.state;
    const searchQuery = e.target.value;
    this.setState({
      searchQuery,
      errorMessage: errorMessage === ERROR_MESSAGES.NO_SEARCH_QUERY && searchQuery ? null : errorMessage,
    });
  };

  onSearchInputKeyDown = async e => {
    const { searchQuery } = this.state;
    if (isEnterPressed(e) && searchQuery) {
      await this.searchMediaLibraryEmbedImages();
    }
  };

  onGridImageClick = image => {
    const { startLoading } = this.props;
    startLoading();
    return this.setSelectedImage({
      ...image,
      previewImageUrl: getUrlFromImageResponseObject({
        blockType: BLOCK_TYPES.MEDIA_LIBRARY_EMBED,
        image,
        imageViewType: IMAGE_VIEW_TYPES.PREVIEW_IMAGE,
      }),
    });
  };

  onImageChoose = () => {
    const { selectedImage, percentageCrop: { aspect } } = this.state;
    this.setState({
      mode: PANEL_MODES.CROP,
      imageLoading: true,
      caption: (selectedImage && selectedImage.caption) || '',
      credit: (selectedImage && selectedImage.credit) || '',
      percentageCrop: { aspect },
    });
  };

  onImageError = image => {
    const { mediaLibraryEmbedImagesList } = this.state;
    const { id } = image;
    this.setState({
      mediaLibraryEmbedImagesList: mediaLibraryEmbedImagesList.filter(item => item.id !== id),
    });
  };

  setCompletedCrop = percentageCrop => {
    this.setState({ percentageCrop });
  };

  onCropAspectTypeChange = type => {
    const percentageCrop = getInitialPercentageCrop(type);
    this.setState({
      percentageCrop,
    });
  };

  onChangeCaption = (e, value) => {
    this.setState({ caption: removeLineBreaks(value) });
  };

  onChangeAlt = (e, value) => {
    this.setState({ alt: removeLineBreaks(value) });
  };

  onCaptionInputKeyDown = e => {
    if (isEnterPressed(e)) {
      this.onAddHandler();
    }
  };

  onImageLoaded = () => {
    this.setState({
      imageLoading: false,
    });
  };

  onBackButtonClick = () => {
    this.setState({
      mode: PANEL_MODES.MEDIA_LIBRARY,
    });
  };

  onAddHandler = async () => {
    const { onAdd } = this.props;
    const { isImageLinkable, linkableImageSettings, alt } = this.state;
    const isValidLinkableUrl = isImageLinkable && validateLinkableImage(linkableImageSettings.targetLink);
    if ((!isImageLinkable || isValidLinkableUrl) && alt) {
      this.setLinkableImageLinkError('');
      return onAdd(await this.getData());
    }
    return this.setLinkableImageLinkError(LINKABLE_IMAGE_URL_ERROR);
  };

  setImages = ({ images, pagination }) => {
    const { finishLoading } = this.props;
    const { searchQuery, mediaLibraryEmbedImagesList } = this.state;
    const imagesFound = images && images.length;
    if (imagesFound) {
      this.setState({
        nextPageUrl: pagination.next,
        mediaLibraryEmbedImagesList: this.resultsPageNumber !== 1 && this.currentSearchRequest === searchQuery ? [...mediaLibraryEmbedImagesList, ...images] : images,
        errorMessage: null,
      });
    } else if (this.resultsPageNumber === 1) {
      this.setState({ mediaLibraryEmbedImagesList: [] });
      this.showErrorMessage(ERROR_MESSAGES.NO_DATA);
    }
    finishLoading();
  };

  setSelectedImage = image => {
    const { finishLoading } = this.props;
    this.setState({
      selectedImage: image,
      caption: image.title,
      alt: image.altText,
    });
    finishLoading();
  };

  getData = () => {
    const { selectedImage, percentageCrop, caption, credit, alt, sizeType, isImageLinkable, linkableImageSettings } = this.state;
    const { linkURL, linkTargetAttribute, linkRelAttribute } = formatImgLinkObject({ linkableImageSettings });
    return {
      image: selectedImage,
      crop: percentageCrop,
      aspectRatio: getCropAspectName(percentageCrop.aspect),
      caption,
      credit,
      alt,
      sizeType,
      ...(isImageLinkable && { linkURL, linkTargetAttribute, linkRelAttribute }),
      provider: MEDIA_LIBRARY_EMBED_PROVIDER,
    };
  };

  searchMediaLibraryEmbedImagesPage = async ({ path }) => {
    const { startLoading } = this.props;
    const { searchQuery } = this.state;
    startLoading();
    try {
      const searchResult = await MediaLibraryImagesNetworkService.getPagedPosts({ path });
      this.setImages(searchResult);
    } catch (e) {
      this.handleMediaLibraryEmbedError(e);
    }
    this.currentSearchRequest = searchQuery;
  };

  getFooterPluginButtonsProps = isMediaLibraryEmbedMode => {
    const { imageLoading } = this.state;
    const { editedBlockData } = this.props;
    return isMediaLibraryEmbedMode ? {
      onAddClick: this.onImageChoose,
      isAddDisabled: this.isChooseButtonDisabled(),
      addButtonText: PLUGIN_BUTTON_CHOOSE_TEXT,
    } : {
      onAddClick: this.onAddHandler,
      isAddDisabled: imageLoading,
      addButtonText: pluginAddButtonTextHandler(editedBlockData),
    };
  };

  loadMoreImages = async () => {
    const { nextPageUrl } = this.state;
    this.resultsPageNumber += 1;
    await this.searchMediaLibraryEmbedImagesPage({ path: nextPageUrl });
  };

  removePreviewImage = () => {
    this.setState({
      selectedImage: null,
    });
  };

  showErrorMessage = payload => {
    const { finishLoading } = this.props;
    this.setState({
      errorMessage: payload,
    });
    finishLoading();
  };

  handleMediaLibraryEmbedError = () => {
    const { finishLoading } = this.props;
    finishLoading();
  };

  searchMediaLibraryEmbedImages = async () => {
    const { startLoading, property } = this.props;
    const { searchQuery } = this.state;
    if (searchQuery) {
      startLoading();
      try {
        const searchResult = await MediaLibraryImagesNetworkService.getImages({
          property: property.slug,
          filters: {
            search: searchQuery,
            status: STATUS_FILTER_VALUES.DASHBOARD.APPROVED,
          },
        });
        this.setImages(searchResult);
      } catch (e) {
        this.handleMediaLibraryEmbedError(e);
      }
      this.currentSearchRequest = searchQuery;
    } else {
      this.setState({
        errorMessage: ERROR_MESSAGES.NO_SEARCH_QUERY,
      });
    }
  };

  isChooseButtonDisabled = () => {
    const { selectedImage } = this.state;
    return !selectedImage;
  };

  shouldShowLoadMoreButton = () => {
    const { nextPageUrl } = this.state;
    return nextPageUrl;
  };

  clearSearchQuery = () => {
    this.setState({
      searchQuery: '',
    });
  };

  renderLoadMoreButton = () => {
    const { loadMoreButton } = getStylesObject();
    return (
      this.shouldShowLoadMoreButton()
        ? (
          <div css={css(loadMoreButton)}>
            <TextButton content={LOAD_MORE_TEXT} onClick={this.loadMoreImages} />
          </div>
          )
        : null
    );
  };

  renderMediaLibraryEmbedPanel = () => {
    const { errorMessage } = this.state;
    const MediaLibraryEmbedHeader = this.renderMediaLibraryEmbedHeader;
    const MediaLibraryEmbedBody = this.renderMediaLibraryEmbedBody;
    const LoadMoreButton = this.renderLoadMoreButton;
    return (
      <Fragment>
        <MediaLibraryEmbedHeader />
        {errorMessage ? <Plugin.ErrorMsgComponent text={errorMessage} /> : <MediaLibraryEmbedBody />}
        <LoadMoreButton />
      </Fragment>
    );
  };

  renderMediaLibraryEmbedHeader = () => {
    const { isLoading } = this.props;
    const { searchQuery } = this.state;
    const { header } = getStylesObject();
    return (
      <div css={css(header)}>
        <SearchInput
          value={searchQuery}
          placeholder={ENTER_SEARCH_PLACEHOLDER}
          disabled={isLoading}
          сlearable="true"
          variables={{ wrapperWidth: '100%', width: '100%' }}
          onChange={this.onSearchInputChange}
          onKeyDown={this.onSearchInputKeyDown}
          onClear={this.clearSearchQuery}
        />
      </div>
    );
  };

  renderMediaLibraryEmbedBody = () => {
    const { mediaLibraryEmbedImagesList, selectedImage } = this.state;
    return mediaLibraryEmbedImagesList.length
      ? (
        <PluginGridWithPreview
          images={mediaLibraryEmbedImagesList}
          selectedImage={selectedImage}
          onImageError={this.onImageError}
          blockType={BLOCK_TYPES.MEDIA_LIBRARY_EMBED}
          onGridImageClick={this.onGridImageClick}
          onImageChoose={this.onImageChoose}
          removePreviewImage={this.removePreviewImage}
        />
      )
      : null;
  };

  renderImageDescription = () => {
    const { caption, credit, alt } = this.state;
    const { shouldShowAuditAndTags } = this.props;
    return (
      <ImageDescription
        shouldShowAuditAndTags={shouldShowAuditAndTags}
        caption={caption}
        credit={credit}
        alt={alt}
        isCreditEditingEnabled={false}
        onChangeCaption={this.onChangeCaption}
        onChangeAlt={this.onChangeAlt}
        onKeyDown={this.onCaptionInputKeyDown}
      />
    );
  };

  renderFullBleedImageTogglerIfNeedTo = () => {
    const { sizeType } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    const shouldRenderFullBleedImageToggler = !shouldAllowHorizontalCropOnly;
    return shouldRenderFullBleedImageToggler ? (
      <FullBleedImageToggler
        checked={sizeType === IMAGE_SIZE_TYPES.FULL_BLEED}
        onToggleChange={() => {
          this.setState({ sizeType: sizeType === IMAGE_SIZE_TYPES.REGULAR
            ? IMAGE_SIZE_TYPES.FULL_BLEED
            : IMAGE_SIZE_TYPES.REGULAR,
          });
        }}
      />
    ) : null;
  };

  onLinkableImageDataChange = data => {
    if (validateLinkableImage(data.targetLink)) {
     this.setLinkableImageLinkError('');
    }
    return this.setLinkableImageSettings(data);
  };

  renderLinkableImageSettings = () => {
    const { isImageLinkable, linkableImageSettings, linkableImageLinkError } = this.state;
    const { shouldAllowHorizontalCropOnly } = this.props;
    return (
      !shouldAllowHorizontalCropOnly && (
        <LinkableImageSettings
          checked={isImageLinkable}
          onToggleChange={this.setIsImageLinkable}
          onLinkableImageDataChange={this.onLinkableImageDataChange}
          linkableImageSettings={linkableImageSettings}
          linkableImageLinkError={linkableImageLinkError}
        />
      )
    );
  };

  renderImageConfigurationIfNeedTo = () => {
    const { selectedImage, imageLoading } = this.state;
    const shouldRenderImageConfiguration = !!selectedImage.previewImageUrl && !imageLoading;
    return shouldRenderImageConfiguration ? (
      <Fragment>
        {this.renderImageDescription()}
        {this.renderLinkableImageSettings()}
        {this.renderFullBleedImageTogglerIfNeedTo()}
      </Fragment>
    ) : null;
  };

  renderCroppingArea = () => {
    const { shouldAllowHorizontalCropOnly } = this.props;
    const { selectedImage, imageLoading, percentageCrop, cropAspectType } = this.state;
    const { editImageContainer } = getStylesObject();
    return (
      <div css={css(editImageContainer)}>
        <CroppingAreaCustomization
          imageLoading={imageLoading}
          hasImage
          src={selectedImage.previewImageUrl}
          percentageCrop={percentageCrop}
          cropAspectType={cropAspectType}
          setCompletedCrop={this.setCompletedCrop}
          onCropAspectTypeChange={this.onCropAspectTypeChange}
          onImageLoaded={this.onImageLoaded}
          shouldAllowHorizontalCropOnly={shouldAllowHorizontalCropOnly}
          backButton
          backButtonOnClick={this.onBackButtonClick}
        />
        {this.renderImageConfigurationIfNeedTo()}
      </div>
    );
  };

  render() {
    const { onCancel } = this.props;
    const { mode, imageLoading } = this.state;
    const isMediaLibraryEmbedMode = mode === PANEL_MODES.MEDIA_LIBRARY;
    return (
      <Plugin.Container>
        <Plugin.Content>
          {isMediaLibraryEmbedMode ? this.renderMediaLibraryEmbedPanel() : this.renderCroppingArea()}
        </Plugin.Content>
        <Plugin.Buttons onCancelClick={onCancel} isCancelDisabled={imageLoading} {...this.getFooterPluginButtonsProps(isMediaLibraryEmbedMode)} />
      </Plugin.Container>
    );
  }
}

// Plugin Data

export const mediaLibraryEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<MediaLibraryEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<MediaLibraryEmbedPanelComponent {...props} />),
  getPluginBlock: props => <ImageBlock {...props} />,
  getPluginOverviewBlock: props => (<ImageBlockOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.IMAGE,
};
