import { Component, useEffect, useRef, useState } from 'react';
import { Icon, Input } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { CerosIcon } from '@ftbpro/mm-admin-assets';
import { BLOCK_TYPES } from '../utils/blocksDescriptorGenerator';
import { getStylesObject } from './styles/embed.styles';
import { Plugin } from './Plugin/Plugin';
import { pluginInputStyle } from './shared/styles/pluginInput.styles';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../utils/plugins.utils';
import { EmbedBlock } from './shared/EmbedBlock';
import { overviewBlockText } from '../utils/blockList.utils';
import { getOembedDataByProxy } from '../services/oembed/oEmbedApiProvider';
import { isEnterPressed } from '../../../../core/utils/keyboard.utils';
import { OEMBED_QUERY_PARAMS } from '../services/oembed/oEmbed.constants';

const CEROS_OEMBED_PREFIX = `https://view.ceros.com/oembed?${OEMBED_QUERY_PARAMS.URL}=`;
export const EMBED_URL_BROKEN_TEXT = 'The embed URL you are using is not from Ceros. Please paste a correct one.';
const ENTER_CEROS_PLACEHOLDER = urlInputPlaceholderGenerator('Ceros');

// Element-Panel Top Bar Button

export const CerosEmbedTopBarIcon = props => {
  return (
    <Icon icon={CerosIcon} width={28} height={28} {...props} />
  );
};

// Element-Panel Panel Component

export class CerosEmbedPanelComponent extends Component {
  constructor(props) {
    super(props);
    const { editedBlockData } = props;
    const isEditingBlock = editedBlockData && editedBlockData.type === BLOCK_TYPES.CEROS;
    this.state = {
      url: isEditingBlock ? editedBlockData.value.originalEmbedUrl : '',
      cerosData: null,
    };
  }

  componentDidMount() {
    const { url } = this.state;
    if (url) {
      this.getUrlOembedData(url);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.blockType === BLOCK_TYPES.CEROS;
  }

  onInputChange = e => {
    const url = e.target.value;
    this.setState({ url }, this.getUrlOembedData(url));
  };

  onAdd = () => {
    const { onAdd } = this.props;
    const { cerosData } = this.state;
    onAdd(cerosData);
  };

  onUrlInputKeyDown = e => {
    const { cerosData } = this.state;
    if (isEnterPressed(e) && cerosData) {
      this.onAdd(cerosData);
    }
  };

  getUrlOembedData = url => {
    const { startLoading, finishLoading, property } = this.props;
    startLoading();
    if (!this.isValidCerosUrl(url)) {
      finishLoading();
      this.setState({ cerosData: null });
      return;
    }
    const oEmbedEndPoint = CEROS_OEMBED_PREFIX + encodeURI(url);
    getOembedDataByProxy(oEmbedEndPoint, url, BLOCK_TYPES.CEROS, property.slug)
      .then(cerosData => {
        finishLoading();
        this.setState({ cerosData });
      })
      .catch(() => {
        finishLoading();
        this.setState({ cerosData: null });
      });
  };

  getPreviewComponent = () => {
    const { isLoading } = this.props;
    const { url, cerosData } = this.state;
    if (!url || isLoading) {
      return null;
    }
    return cerosData && this.isValidCerosUrl(url)
      ? <div dangerouslySetInnerHTML={{ __html: cerosData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };

  clearInput = () => this.setState({ url: '' });

  isValidCerosUrl = url => url.includes('ceros');

  render() {
    const { onCancel, editedBlockData } = this.props;
    const { cerosData, url } = this.state;
    const isAddDisabled = cerosData === null || url === '';
    return (
      <Plugin.Container>
        <Plugin.Content>
          <Input
            value={url}
            placeholder={ENTER_CEROS_PLACEHOLDER}
            autoFocus
            style={pluginInputStyle}
            onChange={this.onInputChange}
            onKeyDown={this.onUrlInputKeyDown}
            clearInput={this.clearInput}
          />
          {this.getPreviewComponent() }
        </Plugin.Content>
        <Plugin.CopyrightInformation />
        <Plugin.Buttons
          onCancelClick={onCancel}
          onAddClick={this.onAdd}
          isAddDisabled={isAddDisabled}
          addButtonText={pluginAddButtonTextHandler(editedBlockData)}
        />
      </Plugin.Container>
    );
  }
}

// Editor Block Component

export const CerosBlock = props => {
  const [loading, setLoading] = useState(true);
  const blockRef = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted()) {
      blockRef.current.querySelector('iframe').addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};

export function CerosEmbedOverview({ value }) {
  const overviewIconStyle = { flex: '0 0 32px', marginRight: '16px' };
  return (
    <Plugin.OverviewBlock>
      <CerosEmbedTopBarIcon width={32} height={32} style={overviewIconStyle} />
      {overviewBlockText(value)}
    </Plugin.OverviewBlock>
  );
}

// Plugin Data

export const cerosEmbedPluginData = {
  getPluginTopBarButtonIcon: props => (<CerosEmbedTopBarIcon {...props} />),
  getPluginPanelComponent: props => (<CerosEmbedPanelComponent {...props} />),
  getPluginBlock: props => <CerosBlock {...props} />,
  getPluginOverviewBlock: props => (<CerosEmbedOverview {...props} />),
  pluginBlockType: BLOCK_TYPES.CEROS,
};
