import { createContext } from 'react';

export interface BackgroundTasksContextValue {
  isTaskExists: (taskId: string) => boolean
}

export const BackgroundTasksContextDefaultValue: BackgroundTasksContextValue = {
  isTaskExists: () => false,
};

export const BackgroundTasksContext = createContext(BackgroundTasksContextDefaultValue);
