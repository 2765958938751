import { IconButton, ButtonType } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/react';
import { DeleteIcon, EditIcon } from '@ftbpro/mm-admin-assets';

import { CSS_CLASSES } from '../editorCover/editorCover.styles';
import { mediaOverlayStyles } from './mediaOverlay.styles';

export const MediaOverlay = ({
  shouldPreventEdit,
  shouldPreventDelete,
  isEditing,
  onEdit,
  onCancelEdit,
  onDelete,
  isImgEditable = true,
}) => {
  return (
    // Classname is used for hover functionality
    <div css={css(mediaOverlayStyles.wrapper)} className={CSS_CLASSES.MEDIA_OVERLAY}>
      <div css={css(mediaOverlayStyles.actions)}>
        {!shouldPreventEdit && (
          <IconButton
            type={ButtonType.Primary}
            icon={EditIcon}
            onClick={onEdit}
            onCancel={onCancelEdit}
            isOnCancelMode={isEditing}
            css={mediaOverlayStyles.button}
            disabled={!isImgEditable}
          />
        )}
        {!shouldPreventDelete && (
          <IconButton
            type={ButtonType.Primary}
            icon={DeleteIcon}
            onClick={onDelete}
            css={mediaOverlayStyles.button}
            disabled={!isImgEditable}
          />
        )}
      </div>
    </div>
  );
};
