import { Filter, Types } from '@ftbpro/mm-admin-ui-components';
import { navigationFiltersSelector } from 'core/store/navigationFilters/navigationFilters.selector';
import { setCustomFilterSelectedValue } from 'core/store/navigationFilters/navigationFiltersSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER_VARIABLES } from './navigationFilters.styles';

export const CustomFilter = () : JSX.Element => {
  const { items = [], placeholder, preSelectedValue, disabled } = useSelector(navigationFiltersSelector.customFilterData) || {};
  const selectedValue = useSelector(navigationFiltersSelector.customFilterSelectedValue) || '';
  const dispatch = useDispatch();

  useEffect(() => {
    if (preSelectedValue) {
      dispatch(setCustomFilterSelectedValue(preSelectedValue));
    }
  }, [preSelectedValue]);

  const onSelectedChange = (e: Types.ClickEventType, { value }: { value: string | number }) => {
    dispatch(setCustomFilterSelectedValue(value as string));
  };

  return (
    <Filter
      items={items}
      placeholder={placeholder}
      disabled={disabled}
      selectedValue={selectedValue}
      onSelectedChange={onSelectedChange}
      variables={FILTER_VARIABLES}
    />
);
};
