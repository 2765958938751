import { DropdownListItemProps } from '@ftbpro/mm-admin-ui-components';
import { Product } from 'core/constants';
import { VoltaxRole } from '../user/roles.constants';
import { HQRole } from '../user/roles.constants.hq';

export enum FilterType {
  TENANT = 'tenant',
  ORGANIZATION = 'organization',
  HQ_PROPERTY = 'hq_property',
  VOLTAX_PROPERTY = 'voltax_property',
  CUSTOM_FILTER = 'custom_filter',
  NONE = 'none',
}

export interface CustomFilterData {
  items?: (string | DropdownListItemProps)[],
  disabled?: boolean,
  placeholder?: string,
  preSelectedValue?: string
  selectedValue?: string,
}

export interface NavigationFiltersState {
  filterType?: FilterType,
  product?: Product,
  disableTenantSelection?: boolean,
  disableOrganizationSelection?: boolean,
  disablePropertySelection?: boolean,
  preSelectedTenant?: string,
  preSelectedOrganization?: string,
  preSelectedProperty?: string,
  allowedRoles?: VoltaxRole[] | HQRole[],
  customFilterData: CustomFilterData
  shouldFilterByProduct?: boolean,
}
