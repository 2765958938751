import { useEffect } from 'react';
import { BackgroundTaskViewer } from '@ftbpro/mm-admin-ui-components';
import { useStyles } from 'core/hooks/useStyles';
import { useSelector, useDispatch } from 'react-redux';
import { WindowHandler } from 'core/window';
import { navigateToPage } from 'core/routing/history';
import { backgroundTasksSelector } from './store/backgroundTasks.selector';
import { removeBackgroundTask } from './store/backgroundTasksSlice';
import { getStylesObject } from './backgroundTasksCenter.styles';
import { initTasksFromLocalStorage } from './backgroundTasksCenter.utils';
import { useBackgroundTasks } from './BackgroundTasksProvider';

export const BackgroundTasksCenter = () => {
  const backgroundTasks = useSelector(backgroundTasksSelector.tasks);
  const dispatch = useDispatch();
  const { isTaskExists } = useBackgroundTasks();
  const styles = useStyles(getStylesObject);

  useEffect(() => {
    initTasksFromLocalStorage(dispatch, isTaskExists);
  }, [dispatch]);

  return (
    <div css={styles.wrapper}>
      {Object.keys(backgroundTasks).map(taskId => {
        const { onClose, errorPageUrl, openErrorPageInNewTab, resourcePageUrl, onErrorTextButtonClick, onDoneTextButtonClick } = backgroundTasks[taskId];
        const onCloseHandler = () => {
          dispatch(removeBackgroundTask(taskId));
          onClose && onClose();
        };

        const onErrorButtonClick = () => {
          if (errorPageUrl) {
            WindowHandler.open(errorPageUrl, openErrorPageInNewTab);
          } else {
            onErrorTextButtonClick && onErrorTextButtonClick();
          }
        };

        const onDoneButtonClick = () => {
          if (resourcePageUrl) {
            navigateToPage(resourcePageUrl);
          } else {
            onDoneTextButtonClick && onDoneTextButtonClick();
          }
        };

        return (
          <BackgroundTaskViewer
            {...backgroundTasks[taskId]}
            onClose={onCloseHandler}
            key={taskId}
            onErrorTextButtonClick={onErrorButtonClick}
            onDoneTextButtonClick={onDoneButtonClick}
          />
        );
      })}
    </div>
  );
};
